.tickets-section{min-height: calc(100vh - 60px);background: transparent linear-gradient(241deg, #FDE6FF 20%, #E3F8FF 100%) 0% 0% no-repeat padding-box;padding: 150px 39px 50px;min-width: calc(100% - 280px);}
.tickets-section .tickets-details h2 {font-weight: 700;font-size: 36px;line-height: 40px;letter-spacing: 2.88px;margin-bottom: 50px;}
.tickets-section .create-button{margin: 0 0 50px;}
.tickets-section .ticket-table{background-color: #fff;border-radius: 10px;box-shadow: 0px 5px 50px #0203070d;padding: 30px;}
.tickets-section .ticket-table .table-img {width: 70px;height: 70px;border-radius: 10px;overflow: hidden;}
.tickets-section .ticket-table .table-img img{width: 100%;height: 100%;object-fit: cover;}
.tickets-section .ticket-table .main-row{min-height: 70px;}
.tickets-section .ticket-table .main-row .view-button, .tickets-section .ticket-table .main-row .delete-button{display: flex;justify-content: center;align-items: center;width: 45px;height: 45px;}
.tickets-section .ticket-table .main-row .delete-button{background-color: red;border-color: red;}
.tickets-section .ticket-table .main-row .view-button{background-color: #401c86;border-color: #401c86;}
.tickets-section .ticket-table .table{table-layout: fixed;}
/* .tickets-section .ticket-table .td-start{width: 100px;} */
.tickets-section .ticket-table .td-image{width: 150px;}
/* .tickets-section .ticket-table .td-title{width: 250px;}
.tickets-section .ticket-table .td-description{width: 568px;}
.tickets-section .ticket-table .td-type{width: 250px;}
.tickets-section .ticket-table .td-actions{width: 150px;} */
.tickets-section .ticket-table .td-start {width: 80px;}
.tickets-section .ticket-table .td-title {width: 170px;}
.tickets-section .ticket-table .td-description {width: 298px;}
.tickets-section .ticket-table .td-type {width: 170px;}
.tickets-section .ticket-table .td-actions {width: 140px;}
/*modal css*/
.uploaded-images .images{margin: 0 auto 20px; border-radius: 4px; overflow: hidden; width: 174px; height: 174px;}
.uploaded-images .images img{width: 100%; height: 100%; object-fit: contain;}
.messenger-section .message-title{margin: 0 0 40px;}
.messenger-section .message-title span{font-size: 24px; line-height: 32px;font-weight: bold;}
.messenger-section .message-area {width: 100%;height: 450px;box-shadow: 0px 3px 26px #3524A524;background-color: #fff;overflow-x: hidden;overflow-y: auto;margin: 0 0 30px;padding: 30px 10px;}
.messenger-section .message-area .chat-box{margin: 0 0 20px;}
.messenger-section .message-area .left-hand {padding: 12px 15px;background-color: #725FEE;border-top-left-radius: 0px;border-top-right-radius: 10px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;max-width: 50%;display: inline-block;word-break: break-word;color: #fff;}
.messenger-section .message-area .right-hand {padding: 12px 15px;background-color: #401c86;border-top-left-radius: 10px;border-top-right-radius: 0px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;max-width: 50%;display: inline-block;word-break: break-word;color: #fff;}
/*messenger scroll bar*/
.messenger-section .message-area::-webkit-scrollbar {background-color: #fff; width: 10px;}
.messenger-section .message-area::-webkit-scrollbar-button { background-color: #fff; }
.messenger-section .message-area::-webkit-scrollbar-track  { background-color:#fff; }
.messenger-section .message-area::-webkit-scrollbar-track-piece  { background-color: #fff;}
.messenger-section .message-area::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box; }
.messenger-section .message-area::-webkit-scrollbar-corner { background-color:#fff;  }
.messenger-section .message-area::-webkit-resizer {background-color:#fff; }
.messenger-section .message-area{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;resize: none;}
/*responsive*/
@media (max-width: 1900px) {
    
}
@media (max-width: 1799px){

}
@media (max-width: 1599px) {
    .tickets-section .tickets-details h2 {font-size: 33px;line-height: 36px;}
    /*table*/
  .tabs-body-table .table {min-width: 858px;}
}
@media (max-width: 1399px) {
    
}
@media (max-width: 1199px) {
    
}
@media (max-width: 991px){
    .tickets-section{padding: 150px 12px 50px;}
    .messenger-section .message-area .right-hand, .messenger-section .message-area .left-hand  {max-width: 55%;}
}
@media (max-width: 767px){
    .tickets-section .tickets-details h2 {font-size: 28px;line-height: 30px;}
    .messenger-section .message-area .right-hand, .messenger-section .message-area .left-hand  {max-width: 65%;}
}
@media (max-width: 575px) {
    .messenger-section .message-area .right-hand, .messenger-section .message-area .left-hand  {max-width: 75%;}
}
@media (max-width: 479px){
    .tickets-section .tickets-details h2 {font-size: 28px;line-height: 30px;}
    .tickets-section{padding: 170px 0px 50px;}
    .messenger-section .message-area .right-hand, .messenger-section .message-area .left-hand  {max-width: 90%;font-size: 14px;}
}
@media (max-width: 360px) {

}
@media (max-width: 319px) {
    .tickets-section .tickets-details h2 {font-size: 26px;line-height: 28px;}
}