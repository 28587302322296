.my-account{ min-height: calc(100vh - 60px); background: transparent linear-gradient(241deg, #FDE6FF 20%, #E3F8FF 100%) 0% 0% no-repeat padding-box;padding: 150px 39px 50px;min-width: calc(100% - 280px); }
.my-account .settings .about-level {background: #fff 0% 0% no-repeat padding-box;box-shadow: 0px 3px 16px #00000029;width: 100%;min-height: 440px;border-radius: 10px;/* background-image: url("../../assets/images/cover-photo.png"); */background-repeat: no-repeat;background-size: contain;margin-bottom: 60px;}
.my-account .settings .profile-settings {background: #fff 0% 0% no-repeat padding-box;box-shadow: 0px 3px 16px #00000029;max-width: 100%;min-height: 756px;margin-bottom: 112px;padding: 20px 30px;border-radius: 10px;}
.my-account .security,
.my-account .email,
.my-account .profile,
.my-account .deleting-account,
.my-account .address-book,
.my-account .password,
.my-account .financial-report,
.my-account .phone,
.my-account .completion,
.my-account .additional-files,
.my-account .address,
.my-account .adress-book,
.my-account .personal-document {
    /* padding: 55px 30px; */
    padding: 15px 0 0 0;
    /* min-height: 682px; */
    box-shadow: none ;background-color: transparent;}
.my-account .security .accordion-header strong {font: normal normal bold 22px/26px Outfit ;letter-spacing: 0px;color: #FFFFFF ;}
.my-account .security .accordion-item {border: none ;background: transparent linear-gradient(180deg, #FDE6FF 0%, #E3F8FF 100%) 0% 0% no-repeat padding-box;}
.my-account .security .accordion-button:focus {box-shadow: none ;}
.my-account .security .accordion-button:not(.collapsed)::after {background-image: none ;transform: rotate(90deg) ;}
.my-account .security .accordion-body ul li span {font: normal normal normal 18px/60px Outfit;letter-spacing: 0px;color: #09091A;line-height: 22px ;min-width: 200px;}
.my-account .security .accordion-body ul li {margin-bottom: 45px;}
.my-account .security .accordion-button:not(.collapsed) {box-shadow: none ;}
.my-account .security .accordion-button::after {background-image: none ;background-repeat: no-repeat;width: 30px;height: 30px;content: url("../../assets/images/Icon ionic-ios-move.svg");display: flex; justify-content: center;align-items: center;}
.my-account .tab-content>.active {background-color: transparent;box-shadow: none;}
.my-account .security .accordion-button {background: #6F60EE;border: none ;}
.my-account .profile-photo {position: absolute;top: 114px;left: 50%;transform: translate(-50%, 0%);border-radius: 100%;border: 10px solid #6d80c4;box-shadow: 0px 3px 16px #00000029;}
.my-account .profile-photo img {width: 212px;height: 212px;object-fit: cover;}
.my-account .cover {height: 230px;}
.my-account .cover img{width: 100%;height: 100%;object-fit: cover;border-top-right-radius: 8px;border-top-left-radius: 8px;}
.my-account .level-img {position: absolute;top: 300px;/* bottom: -120px; */left: 50%;transform: translate(-50%, 0%);}
.my-account .profile-cover {min-height: 300px;}
.my-account .user-name {padding: 0 30px;}
.my-account .user-name strong {font: normal normal bold 24px/32px Outfit;letter-spacing: 0px;color: #09091A;line-height: 28px;}
.my-account .account-inputs { /* width: 784px; */ margin-bottom: 30px;}
.my-account .account-inputs .switch-section {width: 80px;display: flex;justify-content: center;margin-left: 20px;}
.my-account .account-inputs .swtich-tag{width: calc(100% - 100px);}
.my-account .account-inputs .swtich-tag span {font-size: 18px; line-height: 22px;}
.my-account .bank .table thead th {min-width: 242px;}
.my-account .history ::-webkit-scrollbar {width: 5px;}
.my-account .history ::-webkit-scrollbar-track {background-color: #fff;border-radius: 8px;}
.my-account .history ::-webkit-scrollbar-thumb {box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);background: #6779ba;border-radius: 8px;}
.my-account .account-inputs label,
.my-account .phone label,
.my-account .account-inputs-field label,.deleting-account label {font: normal normal normal 18px/60px Outfit;letter-spacing: 0px;color: #09091A;line-height: 22px ;min-width: 200px;margin-bottom: 15px;}
.my-account .password-input-account {position: relative;}
.my-account .circle-add {width: 30px;height: 30px;border-radius: 100%;background-color: #fff;display: flex;justify-content: center;align-items: center;cursor: pointer;transition: all ease-in-out 0.3s}
.my-account .circle-add:hover {background-color: #FFD046;color: #fff;}
.my-account .eye-icon {position: absolute;width: 35px;right: 10px;color: #6D80C4;top: 50%; transform: translateY(-50%);}
.my-account .eye-icon svg {color: #6b81ce;}
.my-account .profile-btn .dropdown button,
.my-account .profile-btn .dropdown button:active {width: fit-content;}
.my-account .profile-btn .dropdown-menu.show {width: -webkit-fill-available;}
.my-account .login .eye-icon {bottom: -3px ;}
.my-account .eye-icon-disabled {position: absolute;bottom: 12px;width: 35px;right: 10px;color: #6D80C4;display: none;}
.my-account .account-inputs-field {width: 300px;margin-bottom: 30px;}
.my-account .deleting-account .card {min-height: 336px;border: 1px solid #fff;background-color: #fff;border-radius: 6px;padding: 30px 15px;margin-bottom: 30px;width: 100%;color: #09091A;box-shadow: 0px 5px 30px #0e4a4814;font-size: 18px;line-height: 23px;}
.my-account .deleting-account .card:focus-visible{outline: none;}
.my-account .deleting-account h2 {font: normal normal normal 24px/60px Outfit;letter-spacing: 0px;color: #09091A;line-height: 28px ;min-width: 200px;margin-bottom: 20px;}
.my-account .deleting-account .card label {font: normal normal normal 24px/60px Outfit;letter-spacing: 0px;color: #FFFFFF;line-height: 28px ;min-width: 200px;margin-bottom: 30px;}
.my-account .financial-report ul li {margin-bottom: 30px;align-items: center;}
.my-account .financial-report span {font: normal normal normal 20px/26px Outfit;letter-spacing: 0px;color: #FFFFFF;}
.my-account .financial-report strong {font: normal normal medium 20px/26px Outfit;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 15px;display: block;}
.my-account .financial-report p {font: normal normal normal 14px/18px Outfit;letter-spacing: 0px;color: #6D80C4;margin-bottom: 40px;}
.my-account .footer-report {min-height: 100px;background-color: #6D80C4;}
.my-account .address-book p {font: normal normal normal 24px/18px Outfit;letter-spacing: 0px;color: #FFFFFF;margin-bottom: 40px;width: 400px;line-height: 28px;margin-bottom: 37px;}
.my-account .css-1okebmr-indicatorSeparator {background-color: #000000 ;}
.my-account .google-icon {color: #401c86;font-size: 20px;margin-right: 15px;}
.my-account .css-1s2u09g-control {padding: 0px ;width: 100%;background-color: transparent ;border: 2px solid #000000 ;color: #fff;border-color: #000000 ;}
.my-account .modal-content button {background-color: #fff;border-radius: 8px;padding: 19px 50px;border: 1px solid #DBB74C;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;}
.my-account .modal-content button span {font: normal normal bold 18px/18px Outfit;letter-spacing: 0px;color: #000000 ;line-height: 22px;}
.my-account .cypto-modal .modal-content .css-4ljt47-MenuList,
.my-account .bank-modal .css-4ljt47-MenuList {background: #ffd046 ;border: 1px solid ;}
.my-account .css-26l3qy-menu {z-index: 3 ;}
.my-account .css-tj5bde-Svg {color: #000000;}
.my-account .action-circle {width: 30px;height: 30px;border-radius: 100%;display: flex;justify-content: center;align-items: center;background-color: transparent;border: 1px solid #fff;}
.my-account .delete-modal .modal-header,
.my-account .delete-modal .modal-footer {border: none;}
.my-account .delete-modal button {background-color: #DBB74C;border: 1px solid #DBB74C;padding: 19px 30px;}
.my-account .delete-btn {background-color: transparent ;border: none ;color: #000000 ;}
.my-account .action-btn {background-color: transparent ;border: none ;padding: 0% ;}
/**/
.my-account .cover-uploader {position: absolute;right: 70px;bottom: 100px;}
.my-account .camera-circle, .navbar-component a {display: flex;justify-content: center;align-items: center;}
.my-account .camera-circle {background: #6d80c4;width: 80px;height: 80px;border-radius: 100%;border: 1px solid #fff;}
.my-account input {background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 5px 30px #0e4a4814;border-radius: 6px;font-size: 18px;line-height: 23px;border: none;padding: 21px 20px;width: 100%;height: 65px;color: #09091A;}
.my-account input::placeholder {font: normal normal normal 18px/32px Outfit;letter-spacing: 0px;color: rgbs(9, 9, 26, 0.5);line-height: 22px;}
.my-account input:focus-visible{outline: none;}
.my-account .address input[type=file], .my-account .passport input[type=file], .my-account input[type=file] {display: none;}
.my-account input[type=checkbox]{padding: 15px 15px 14px 40px;color: #000;border-radius: 30px;height: auto;border: 1px solid rgb(161 155 155);}
.my-account .form-switch .form-check-input:checked {background-image: url("../../assets/images/circle-purple.svg");}
.my-account .form-switch .form-check-input:checked:focus {background-image: url("../../assets/images/circle-purple.svg");}
.my-account .form-switch .form-check-input:focus{box-shadow: none;background-image: url("../../assets/images/circle-silver.svg");}
.my-account .wallet-details h2 {font-weight: 700;font-size: 36px;line-height: 40px;letter-spacing: 2.88px;margin-bottom: 50px;}
.my-account .wallet-details h3 {font-weight: 700;font-size: 24px;line-height: 32px;letter-spacing: 2.88px;margin-bottom: 50px;}
.my-account .profile-uploader {position: absolute;top: 271px;left: 51%;-webkit-transform: translate(134%);transform: translate(134%);}
.my-account .profile-uploader label {display: inline-block;}
.my-account .profile-uploader .camera-circle-dp {background: #6d80c4;width: 40px;height: 40px;border-radius: 100%;display: flex;justify-content: center;align-items: center;border: 1px solid #fff;}
.my-account .tabs-section .tabs-header {background: #6F60EE;padding: 20px;border-radius: 10px;}
.my-account .tabs-section .tabs-header .nav-item{margin: 0 28px;}
.my-account .tabs-section .tabs-header .nav-item .nav-link{color: #fff;padding: 8px 17px;min-width: 82px;text-align: center;}
.my-account .tabs-section .tabs-header .nav-item .nav-link.active, .my-account .tabs-section .tabs-header .nav-item .show>.nav-link{background: #401c86;}
.my-account .adress-book .adress-para p:first-of-type{margin: 0;}
.my-account .adress-book .adress-para p {font: normal normal normal 24px/18px Outfit;letter-spacing: 0;color: #09091A;line-height: 28px;margin-bottom: 37px;}
.my-account .adress-book .adress-image{margin: 0 0 20px;}
/**/
.address-book-create .modal-header {margin-bottom: 45px;position: relative;justify-content: center;}
.address-book-create .modal-header .btn-close {position: absolute;right: 20px;top: 50%;transform: translateY(-50%);}
.address-book-create .modal-content {background: transparent linear-gradient(180deg, #FDE6FF 0%, #E3F8FF 100%) 0% 0% no-repeat padding-box;border: none;padding: 20px 0 30px;}
.address-book-create .tab-content .modal-content {overflow: auto;height: 650px;margin-top: 30px;}
.address-book-create .modal-title {font: normal normal bold 24px/32px Outfit;letter-spacing: 0px;color: #000000;text-align: left;}
.address-book-create .modal-body,
.address-book-create .modal-header,
.address-book-create .modal-footer {border: none;}
.address-book-create .modal-body strong {font: normal normal bold 14px/18px Outfit ;letter-spacing: 0px;color: #000000 ;display: block;margin-bottom: 20px;}
.address-book-create .modal-body label {font: normal normal 600 20px/26px Outfit;letter-spacing: 0px;color: #000000;margin-bottom: 15px;}
.address-book-create .modal-body .input-result {letter-spacing: 0px;color: #000000;font-size: 20px;line-height: 26px;font-weight: normal;margin-left: 15px;word-break: break-word; max-width: 100%;}
.address-book-create .modal-body .input-field {width: 100%;margin-bottom: 30px;}
.address-book-create .modal-body .input-field input {background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 5px 30px #0e4a4814;border-radius: 6px;font-size: 18px;line-height: 23px;border: none;padding: 21px 20px;width: 100%;height: 65px;}
.address-book-create .modal-body .input-field input:focus-visible{outline: none;}
.address-book-create .modal-footer button {padding: 12px 50px;background-color: #fff;border-radius: 8px;}
.address-book-create .modal-footer button span {font: normal normal 600 20px/26px Outfit;}
.address-book-create .modal-body .tab-div {background-color: #fff;padding: 10px 20px;border-radius: 10px;}
.address-book-create .modal-body .tab-div  .nav-pills .nav-link{color: #09091A;min-width: 90px;text-align: center;}
.address-book-create .modal-body .tab-div  .nav-pills .nav-link.active, .address-book-create .modal-body .tab-div  .nav-pills .show>.nav-link{background-color: #6F60EE;color: #fff;}
.address-book-create .triage-select__control{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 5px 30px #0e4a4814;border-radius: 6px;font-size: 18px;line-height: 23px;border: none;padding: 10px 20px;width: 100%;height: 65px;}
.address-book-create .triage-select__control:hover{border-color: #000000;}
.address-book-create .triage-select__input-container input{height: auto !important;}
/*scroll*/
.address-book-create .modal-content::-webkit-scrollbar{display: none;}
.security .accordion ul{display:flex;flex-wrap: wrap;}
.security .accordion ul li{width: 47%;}
/*responsive*/
@media (max-width: 1900px) {
    .my-account {width: 100%;}
    .my-account .settings .about-level,
    .my-account .financial-report .footer,
    .my-account .deleting-account .card,
    .settings .profile-settings,
    .my-account {width: 100%;}
    .my-account .settings .profile-settings {width: 100%;}
}
@media (max-width: 1799px) {
    .my-account .address-book p,
    .address-book-create .modal-body .input-field {width: 100%;}
    .my-account .settings .about-level,
    .my-account .financial-report .footer,
    .my-account .deleting-account .card,
    .my-account .my-account {width: 100%;}
}
@media (max-width: 1599px) {
    .my-account .tabs-section .tabs-header .nav-item { margin: 0 16px;}
    .my-account .wallet-details h2 {font-size: 33px;line-height: 36px;}
}
@media (max-width: 1399px){
    .security .accordion ul li{width: 100%;}
    .my-account .tabs-section .tabs-header .nav-item {margin: 0 5px;}
    .my-account .profile-photo img {width: 180px;height: 180px;}
    .my-account .level-img {top: 280px;}
    .my-account .profile-uploader {top: 235px;}
    .my-account .security .accordion-body ul{padding: 0;}
    .my-account .security .accordion-body ul li{margin-left: 0 !important;}
}
@media (max-width: 1199px){
    .my-account .security, .my-account .email, .my-account .profile, .my-account .deleting-account, .my-account .address-book, .my-account .password, .my-account .financial-report, .my-account .phone, .my-account .completion, .my-account .additional-files, .my-account .address, .my-account .adress-book, .my-account .personal-document {overflow: hidden;}
    .my-account .account-inputs {width: 100%;}
    .my-account .adress-book .adress-para p {font: normal normal normal 18px/18px Outfit;}
    /*new*/
    .my-account .account-inputs .swtich-tag {width: calc(100% - 80px);}
    .my-account .account-inputs .switch-section {width: 70px;margin-left: 10px;}
    .my-account .deleting-account .card {width: 96%;margin: 0px 2% 30px;}
    .my-account input {font-size: 16px;line-height: 20px;margin: 0px 1%;width: 98%;}
    .my-account input::placeholder {font-size: 16px;line-height: 20px;}
    .my-account .deleting-account .card, .my-account input {
        box-shadow: 0px 3px 8px #00000029;
    }
}
@media (max-width: 991px) {
    .my-account .account-inputs {width: 100%;}
    .my-account {padding: 150px 12px 50px;}
    /* .my-account .security, .my-account .email, .my-account .profile, .my-account .deleting-account, .my-account .address-book, .my-account .password, .my-account .financial-report, .my-account .phone, .my-account .completion, .my-account .additional-files, .my-account .address, .my-account .adress-book, .my-account .personal-document {padding: 55px 15px;} */
}
@media (max-width: 767px){
    .my-account .cover-uploader {right: 45px;}
    .my-account .camera-circle {width: 60px;height: 60px;}
}
@media (max-width: 575px) {
    .my-account .cover {min-height: 180px ;width: 100% ;}
    .my-account .user-name {flex-direction: column;text-align: center;padding: 0 0 30px;}
    .my-account .email-address {margin: 0 0 20px;display: block;}
    /*new*/
    .my-account .wallet-details h3 {font-size: 20px;line-height: 30px;}
}
@media (max-width: 479px) {
    .my-account .level-img {position: absolute;top: 280px;}
    .my-account .financial-report {padding: 51px 13px;}
    .my-account .financial-report span {font: normal normal normal 15px/26px Outfit;line-height: 19px;}
    .my-account {padding: 170px 12px 50px;}
    .my-account .wallet-details h2 {font-size: 28px;line-height: 30px;}
    .my-account {padding: 170px 0px 50px;}
    .my-account .settings .profile-settings {padding: 20px 12px;}
    .my-account input {font-size: 14px;line-height: 16px;}
    .my-account input::placeholder {font-size: 14px;line-height: 16px;}
    .my-account .security .accordion-body ul li {margin-left: 0 !important;flex-direction: column;align-items: center;text-align: center;}
    /* .my-account .security, .my-account .email, .my-account .profile, .my-account .deleting-account, .my-account .address-book, .my-account .password, .my-account .financial-report, .my-account .phone, .my-account .completion, .my-account .additional-files, .my-account .address, .my-account .adress-book, .my-account .personal-document {padding: 54px 0px;} */
    .my-account .google-icon {display: none;}
    .my-account .security .accordion-header strong {font: normal normal bold 16px/22px Outfit;}
    .my-account .security .accordion-body ul li span {font: normal normal normal 15px/22px Outfit;}
    .address-book-create .modal-body label {font: normal normal 600 16px/22px Outfit;}
    .address-book-create .modal-body .input-field input {font-size: 14px;line-height: 18px;}
    .address-book-create .triage-select__input-container input {height: auto !important;font-size: 14px !important;}
    .address-book-create .triage-select__control {font-size: 16px;line-height: 18px;}
    .my-account .profile-photo img {width: 140px;height: 140px;}
    .my-account .profile-photo {top: 170px;}
    .my-account .profile-uploader {top: 245px;}
    .my-account .cover-uploader {bottom: auto;top: 0;right: 0;}
    .my-account .camera-circle {width: 50px;height: 50px;border-radius: 0%;}
    .address-book-create .modal-title {font: normal normal bold 16px/22px Outfit;}
    .my-account .level-img {top: 292px;width: 15%;}
    /**/
    .address-book-create .modal-footer button {min-width: 166px;}
    .address-book-create .modal-body .input-result {font-size: 16px;line-height: 22px;margin-left: 0px;display: block;}
    /*new*/
    .my-account .wallet-details h3 {font-size: 18px;line-height: 26px;}
    .my-account .account-inputs .swtich-tag {font-size: 15px;}
    .my-account .account-inputs label, .my-account .phone label, .my-account .account-inputs-field label, .deleting-account label {font-size: 15px;line-height: 20px;}
    .my-account .account-inputs .swtich-tag span {font-size: 15px;line-height: 20px;}
}
@media (max-width: 360px) {
    .my-account .tabs-section {padding: 10px 0;}
}
@media (max-width: 319px) {
    .my-account .profile-photo {position: absolute;top: 148px;}
    .my-account .adress-book .adress-para p {font: normal normal normal 15px/16px Outfit;}
    .my-account .profile-photo img {width: 123px;height: 123px;}
    .my-account .level-img {top: 266px;width: 15%;}
    .my-account .profile-uploader {top: 210px;left: 46%;}
    .address-book-create .modal-body .tab-div .nav-pills .nav-link {color: #09091A;min-width: 75px;text-align: center;}
    .my-account .wallet-details h2 {font-size: 26px;line-height: 28px;}
    /*new*/
    .my-account .wallet-details h3 {font-size: 16px;line-height: 23px;}
    .my-account .account-inputs .swtich-tag {width: calc(100% - 66px);}
    .my-account .account-inputs .switch-section {width: 58px;margin-left: 8px;}
    .my-account .account-inputs .swtich-tag {font-size: 14px;}
}