.packages-section{padding: 104px 0px 150px;}
.packages-section .packages-head{margin: 0 0 144px;}
.packages-section .packages-head p{max-width: 1290px;margin: 0 auto;}
.packages-section .package-content h3{font-size: 48px;line-height: 60px;font-weight: bold;margin: 0 0 7px;text-transform: uppercase;}
.packages-section .package-content .blue-head{font-size: 24px;line-height: 26px;color: #725FEE;text-transform: uppercase;margin: 0 0 30px;display: block;}
.packages-section .package-content p{max-width: 860px;}
.packages-section .package-content button{min-width: 199px;}
.packages-section .package-content .three-boxes{margin: 0 0 55px;}
.packages-section .package-content .three-boxes .inner-boxes{box-shadow: 0px 3px 26px #0203071F;border: 1px solid #D4D2D2;border-radius: 4px;padding: 20px;max-width: 277px;margin:0 25px 10px 0 ;}
.packages-section .package-content .three-boxes .inner-boxes .tick{display: block;margin-right: 16px;width: 17px;color: #725FEE;font-size: 20px;}
.packages-section .package-content .three-boxes .inner-boxes .box-text{width: calc(100% - 33px);font: normal normal normal 20px/26px Outfit;opacity: 0.51;}
.packages-section .bronze-area{margin: 0 0 100px;}
.packages-section .silver-area{background: #DEC2E417 0% 0% no-repeat padding-box;padding: 100px 0 120px;margin: 0 0 150px;}
/**/
.packages-section .rewards-section {width: 181px;height: 130px;background-color: #fff;box-shadow: 0px 3px 26px #0203071f;position: absolute;border-radius: 12px;text-align: center;display: flex;flex-direction: column;justify-content: center;align-items: center;}
.packages-section .rewards-section::after{content:"";position: absolute;bottom: -20px;left: 50%;transform: translateX(-50%);border-left: 20px solid transparent;border-right: 20px solid transparent;border-top: 20px solid #fff;}
.packages-section .bronze-area .rewards-section {right: -20px;top: -27%;}
.packages-section .silver-area .rewards-section {right: -14%;top: 14%;}
.packages-section .rewards-section .reward-text{font-size: 20px;line-height: 26px;color: #725FEE;display: block;margin: 0 0 10px;}
.packages-section .rewards-section .percent{font-size: 56px;line-height: 60px;font-weight: bold;color: #725FEE;display: block;}
/*lottie files*/
.packages-section .package-img {position: relative;background: transparent linear-gradient(270deg, #E3F8FF 0%, #FDE6FF 80%) 0% 0% no-repeat padding-box;box-shadow: 0px 3px 36px #725fee26;border: 4px solid #FFFFFF;border-radius: 22px;}
.packages-section .package-img {min-height: 414px;max-width: 535px;}
.packages-section .bronze-area .package-img {margin: 0 0 0 auto;}
.packages-section .silver-area .package-img {margin: 0 auto 0 0;}
.packages-section .package-img img {position: absolute;bottom: 20px;width: 85%;}
.packages-section .bronze-area .package-img img {right: 0;}
.packages-section .silver-area .package-img img {left: 0;}
.packages-section .package-img .bronze-pic{width: 475px;max-height: 374px;}
.packages-section .package-img .silver-pic{width: 475px;max-height: 374px;}
.packages-section .package-img .gold-pic{width: 475px;max-height: 374px;}
.packages-section .package-img .bronze{position: absolute;top: -66%;left: -48%;width: 300px;height: 300px;overflow: hidden;transform: translate(50%, 50%);}
.packages-section .package-img .silver{position: absolute;top: -77%;left: -6%;width: 300px;height: 300px;overflow: hidden;transform: translateY(50%);}
.packages-section .package-img .gold{position: absolute;top: -66%;left: -48%;width: 300px;height: 300px;overflow: hidden;transform: translate(50%, 50%);}
/* responsive */
@media only screen and (max-width:1599px){
    .packages-section .package-content h3 {font-size: 42px;line-height: 55px;}
    .packages-section .package-content .blue-head {font-size: 22px;line-height: 24px;}
    .packages-section .package-content .three-boxes .inner-boxes {padding: 20px 12px;max-width: 240px;}
    .packages-section .package-content .three-boxes .inner-boxes .box-text {font: normal normal normal 18px/24px Outfit;}
    .packages-section .package-img #bronze{top: 20px;width: 240px;height: 240px;}
    .packages-section .package-img #gold{top: 20px;width: 240px;height: 240px;}
    .packages-section .package-img #silver{top: -87px;width: 240px;height: 240px;}
    /*new*/
    .packages-section .package-img {min-height: 380px;max-width: 460px;}
    .packages-section .rewards-section .reward-text {font-size: 18px;line-height: 24px;}
    .packages-section .rewards-section .percent {font-size: 45px;line-height: 50px;}
    .packages-section .rewards-section {height: 120px;}
    .packages-section .package-img .gold, .packages-section .package-img .bronze{top: -60%;width: 250px;height: 250px;}
    .packages-section .package-img .silver{top: -70%;width: 250px;height: 250px;}
}
@media only screen and (max-width:1399px){
    .packages-section .package-content .blue-head {font-size: 20px;line-height: 24px;}
    .packages-section .package-content h3 {font-size: 40px;line-height: 52px;}
    .packages-section .package-content .three-boxes .inner-boxes .tick {margin-right: 10px;width: 10px;font-size: 15px;}
    .packages-section .package-content .three-boxes .inner-boxes .box-text {width: calc(100% - 20px);font: normal normal normal 16px/22px Outfit;}
    .packages-section .package-content .three-boxes .inner-boxes {max-width: 205px;}
    .packages-section .package-img #bronze{top: 0px;}
    .packages-section .package-img #gold{top: 0px;}
    .packages-section .package-img #silver{top: -95px;}
    /*new*/
    .packages-section .package-img{min-height: 350px;max-width: 410px;}
    .packages-section .package-img .gold, .packages-section .package-img .bronze{width: 220px;height: 220px;}
    .packages-section .package-img .silver{top: -68%;width: 220px;height: 220px;}
    .packages-section .rewards-section {width: 140px;}
    .packages-section .rewards-section .percent {font-size: 40px;line-height: 45px;}
}
@media only screen and (max-width:1199px){
    .packages-section .package-content .three-boxes .inner-boxes{max-width: 170px;}
    .packages-section .package-img #bronze{top: -15px;}
    .packages-section .package-img #gold{top: -15px;}
    .packages-section .package-img #silver{top: -100px;}
    /*new*/
    .packages-section .package-img .silver{top: -60%;left: -51%;transform: translate(50%, 50%);}
    .packages-section .package-img .gold, .packages-section .package-img .bronze{left: -51%;}
    .packages-section .silver-area .rewards-section{right: -20px;top: -27px;}
}
@media only screen and (max-width:991px){
    .packages-section .package-img{width: 80%;margin: 0 auto;}
    .packages-section .package-content .three-boxes .inner-boxes{max-width: 30%;}
    .packages-section .package-img #bronze{top: 0px;}
    .packages-section .package-img #gold{top: 0px;}
    .packages-section .package-img #silver{top: -95px;}
    .packages-section{padding: 75px 0px 150px;}
    .packages-section .silver-area{margin: 0 0 70px;}
    /*new*/
    .packages-section .bronze-area .package-img, .packages-section .silver-area .package-img{margin: 0 auto 40px;}
    .packages-section .bronze-area{padding: 100px 0 0;}
}
@media only screen and (max-width:767px){
    .packages-section .package-content .three-boxes{flex-wrap: wrap;}
    .packages-section .package-content .three-boxes .inner-boxes{max-width: 48%;margin: 0 10px 15px 0px;}
    .packages-section .package-content .three-boxes{margin: 0 0 25px;}
    .packages-section .package-content h3{font-size: 35px;line-height: 47px;}
    .packages-section .package-content .blue-head{font-size: 18px;line-height: 22px;}
    .packages-section .package-img{width: 90%;}
}
@media only screen and (max-width:575px){
    .packages-section .package-content button{min-width: 160px;}
    /*new*/
    .packages-section .package-content .three-boxes .inner-boxes{max-width: 47%;}
}
@media only screen and (max-width:479px){
    .packages-section .package-img{width: 100%;}
    .packages-section .package-content h3{font-size: 30px;line-height: 42px;}
    .packages-section .package-content .three-boxes .inner-boxes{max-width: 100%;}
    .packages-section .package-img #bronze{top: -8px;width: 190px;height: 190px;}
    .packages-section .package-img #gold{top: -8px;width: 190px;height: 190px;}
    .packages-section .package-img #silver{top: -82px;width: 190px;height: 190px;}
    .packages-section .packages-head{margin: 0 0 80px;}
    /*new*/
    .packages-section .package-img {min-height: 300px;}
    .packages-section .bronze-area .rewards-section {height: 106px;right: -10px;top: -27%;width: 132px;}
    .packages-section .package-img .gold, .packages-section .package-img .bronze, .packages-section .package-img .silver {width: 190px;height: 190px;left: -54%;}
    /**/
    .packages-section .silver-area .rewards-section {right: -10px;top: -90px;}
}
@media only screen and (max-width:319px){
    .packages-section .package-content h3{font-size: 27px;line-height: 39px;}
    .packages-section .package-img #bronze{top: -3px;width: 160px;height: 160px;left: -45px;}
    .packages-section .package-img #gold{top: -3px;width: 160px;height: 160px;left: -45px;}
    .packages-section .package-img #silver{top: -70px;width: 160px;height: 160px;}
}