.home-banner{background-image: url("../../assets/images/banner-img.png");background-repeat: no-repeat;background-size: 100% 100%;padding: 250px 0;}
.home-banner .banner-data .small-tag{font-size: 60px;line-height: 76px;display: block;font-weight: normal;}
.home-banner .banner-data .small-tag strong{font-weight: normal;}
.home-banner h1{font-size: 103px;line-height: 130px;font-weight: bold;margin: 0 0 30px;}
.home-banner p{font-size: 22px;line-height: 32px;opacity: 0.5;margin: 0 0 100px;max-width: 800px;}
.home-banner .banner-buttons .purple-border-btn{margin-right: 30px;}
.home-banner .realtime-value{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 5px 50px #04040C0F;border-radius: 20px;}
.home-banner .realtime-value .card-header{border-bottom: 1px solid #D6D4E2;padding: 30px 0;}
.home-banner .realtime-value .card-header span{font-size: 30px;line-height: 38px;font-weight: bold;display: block;}
.home-banner .realtime-value .card-body{padding: 0 50px 10px;}
.home-banner .realtime-value .card-body .realtime-data{padding: 40px 0;border-bottom: 1px solid #D6D4E2;}
.home-banner .realtime-value .card-body .realtime-data:last-of-type{border: none;}
.home-banner .realtime-value .card-body .realtime-data .currency-value{max-width: 130px;}
.home-banner .realtime-value .card-body .realtime-data .currency-value .main-value{font-size: 30px;line-height: 38px;font-weight: normal;display: block;margin: 0 0 5px;}
.home-banner .realtime-value .card-body .realtime-data .currency-value .arc-value{font-size: 20px;line-height: 25px;font-weight: normal;display: block;}
.home-banner .realtime-value .card-body .realtime-data .currency-value .arc-value span{font-size: inherit;line-height: inherit;}
.home-banner .realtime-value .card-body .realtime-data .currency-value .arc-value span .icon{font-size: 17px;line-height: 17px;}
.home-banner .realtime-value .card-body .realtime-data .currency-data{width: calc(100% - 130px);}
.home-banner .realtime-value .card-body .realtime-data .currency-data .currency-img{width: 90px;height: 90px;border-radius: 50%;overflow: hidden;margin-right: 15px;}
.home-banner .realtime-value .card-body .realtime-data .currency-data .currency-name{width: calc(100% - 105px);}
.home-banner .realtime-value .card-body .realtime-data .currency-data .currency-name .main-value{font-size: 30px;line-height: 38px;font-weight: normal;display: block;margin: 0 0 5px;}
.home-banner .realtime-value .card-body .realtime-data .currency-data .currency-name .light-value{font-size: 20px;line-height: 25px;font-weight: normal;display: block;color: #B1ACAC;}
.home-banner .fxm-tag{color: #fff;font-size: 20px;font-weight: 600;}
/* responsive */
@media only screen and (max-width:1799px){
    .home-banner .banner-data .small-tag {font-size: 55px;line-height: 71px;}
    .home-banner h1 {font-size: 95px;line-height: 122px;}
    .home-banner .realtime-value .card-body {padding: 0 40px 10px;}
    .home-banner .realtime-value .card-body .realtime-data .currency-data .currency-name .main-value, .home-banner .realtime-value .card-body .realtime-data .currency-value .main-value {font-size: 25px;line-height: 33px;}
}
@media only screen and (max-width:1599px){
    .home-banner .banner-data .small-tag {font-size: 50px;line-height: 66px;}
    .home-banner h1 {font-size: 85px;line-height: 112px;}
    .home-banner .realtime-value .card-body {padding: 0 25px 10px;}
    .home-banner .realtime-value .card-body .realtime-data .currency-data .currency-img {width: 75px;height: 75px;}
    .home-banner .realtime-value .card-body .realtime-data .currency-data .currency-name { width: calc(100% - 90px);}
    .home-banner .realtime-value .card-body .realtime-data .currency-data .currency-name .main-value, .home-banner .realtime-value .card-body .realtime-data .currency-value .main-value {font-size: 22px;line-height: 33px;}
    .home-banner .realtime-value .card-body .realtime-data .currency-value {max-width: 95px;}
    .home-banner .realtime-value .card-body .realtime-data .currency-data {width: calc(100% - 95px);}
}
@media only screen and (max-width:1399px){
    .home-banner .banner-buttons .purple-border-btn {margin-right: 20px;}
    .home-banner .banner-data .small-tag {font-size: 45px;line-height: 60px;}
    .home-banner h1 {font-size: 70px;line-height: 100px;}
    .home-banner p {font-size: 18px;line-height: 26px;margin: 0 0 70px;}
    .home-banner .realtime-value .card-header span {font-size: 26px;line-height: 36px;}
}
@media only screen and (max-width:1199px){
    .home-banner { padding: 220px 0;}
    .home-banner .banner-data .small-tag {font-size: 40px;line-height: 55px;}
    .home-banner h1 {font-size: 60px;line-height: 95px;}
    .home-banner p {font-size: 18px;margin: 0 0 50px;}
}
@media only screen and (max-width:575px){
    .home-banner .banner-data .small-tag {font-size: 35px;line-height: 50px;}
    .home-banner h1 {font-size: 50px;line-height: 85px;}
}
@media only screen and (max-width:479px){
    .home-banner {padding: 160px 0;}
    .home-banner .banner-data .small-tag {font-size: 26px;line-height: 40px;}
    .home-banner h1 {font-size: 35px;line-height: 75px;margin: 0 0 20px;}
    .home-banner .realtime-value .card-header span {font-size: 22px;line-height: 29px;}
    .home-banner .realtime-value .card-body .realtime-data {flex-direction: column;}
    .home-banner .realtime-value .card-body .realtime-data .currency-data {width: 100%;margin: 0 0 20px;}
    .home-banner .realtime-value .card-body .realtime-data .currency-value {max-width: 100%;}
    .home-banner {background-size: cover;background-position: center;}
    /**/
    .home-banner .purple-btn { padding: 10px 22px 10px 35px; }
    /**/
    .home-banner .fxm-tag {font-size: 17px;}
}
@media only screen and (max-width:319px){
    .home-banner .banner-data .small-tag {font-size: 23px;}
    .home-banner h1 {font-size: 30px;}
    .home-banner p {font-size: 16px;}
    .home-banner .realtime-value .card-body .realtime-data .currency-data .currency-img {width: 60px;height: 60px;}
    .home-banner .realtime-value .card-body .realtime-data .currency-data .currency-name {width: calc(100% - 75px);}
    /**/
    .home-banner .banner-buttons {flex-direction: column;}
    .home-banner .banner-buttons  .purple-border-btn {margin: 0 0 10px;}
    .home-banner .banner-buttons .purple-border-btn {margin-right: 0px;}
    .home-banner .banner-buttons button, .home-banner .banner-buttons a{min-width: 182px;}
}