.main-banner{background-image: url("../../assets/images/main-banner.bg.png");background-repeat: no-repeat;background-size: 100% 100%;padding: 250px 0;}
.main-banner h2{font-size: 80px;line-height: 101px;font-weight: bold;margin: 0 0 29px;}
.main-banner p{max-width: 800px;margin: 0 auto 40px;}
.main-banner .inner-banner a{min-width: 141px;display: inline-flex;justify-content: center; align-items: center;}
/*social-icons*/
.main-banner .social-icons ul{padding: 0;list-style: none;}
.main-banner .social-icons ul li{margin: 0 13px;}
.main-banner .social-icons ul li:first-of-type{margin-left: 0;}
.main-banner .social-icons ul li a{width: 83px;height: 83px;background-color: #f7f1ff;border: 2px solid #fff;border-radius: 50%;display: flex;justify-content: center;align-items: center;transition: all ease-in-out 0.3s;}
.main-banner .social-icons ul li a:hover{border: 2px solid #fff;}
.main-banner .social-icons ul li a svg{font-size: 30px;}
/*social-icons-colors*/
.main-banner .facebook{color: #1877f2;}
.main-banner .facebook:hover{background-color: #1877f2;color: #fff;}
.main-banner .linkedin{color: #0a66c2;}
.main-banner .linkedin:hover{background-color: #0a66c2;color: #fff;}
.main-banner .twitter{color: #1da1f2;}
.main-banner .twitter:hover{background-color: #1da1f2;color: #fff;}
.main-banner .pininterest{color: #bd081c;}
.main-banner .pininterest:hover{background-color: #bd081c;color: #fff;}
.main-banner .youtube{color: #ff0000;}
.main-banner .youtube:hover{background-color: #ff0000;color: #fff;}
/* responsive */
@media only screen and (max-width:1919px){

}
@media only screen and (max-width:1799px){
    .main-banner h2 {font-size: 65px;line-height: 90px;}
}
@media only screen and (max-width:1599px){
    .main-banner h2 {font-size: 55px;line-height: 85px;}
}
@media only screen and (max-width:1399px){

}
@media only screen and (max-width:1199px){
    .main-banner h2 {font-size: 45px;line-height: 70px;}
    .main-banner .social-icons ul li a {width: 70px;height: 70px;}
}
@media only screen and (max-width:991px){
    .main-banner h2 {font-size: 40px;line-height: 60px;}
}
@media only screen and (max-width:767px){
    .main-banner {padding: 210px 0;}
    .main-banner h2 {font-size: 35px;line-height: 50px;}
}
@media only screen and (max-width:575px){
    .main-banner h2 {font-size: 32px;line-height: 40px;}
    .main-banner .social-icons ul li a { width: 55px; height: 55px;}
    .main-banner .social-icons ul li a svg { font-size: 22px;}
}
@media only screen and (max-width:479px){
    .main-banner h2 {font-size: 30px;line-height: 38px;}
    .main-banner .inner-banner a {min-width: 110px;}
    .main-banner {background-size: cover;background-position: center;}
    .main-banner .social-icons ul {flex-wrap: wrap;}
    .main-banner .social-icons ul li:first-of-type {margin-left: 13px;}
}
@media only screen and (max-width:319px){
    .main-banner h2 {font-size: 25px;line-height: 30px;}
}