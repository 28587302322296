.choose-us{padding: 102px 0 100px;}
.choose-us .choose-head{margin: 0 0 100px;}
.choose-us .choose-head h2{margin: 0 0 30px;}
.choose-us .choose-head p{max-width: 1292px;margin: 0 auto; padding: 0 0 30px;}
.choose-us .choose-data{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 5px 50px #0203070D;width: 100%;padding: 107px 31px 53px;position: relative;text-align: center;}
.choose-us .choose-data .lottie-cover{width: 132px;height: 132px;border-radius: 50%;margin: 0 auto;box-shadow: 0px 5px 50px #0203070D;position: absolute;left: 50%;transform: translateX(-50%);top: -75px;z-index: 1;overflow: hidden;background-color: #fff;}
.choose-us .choose-data h2{font-size: 24px;line-height: 30px;font-weight: bold;margin: 0 0 21px;}
.choose-us .choose-data p{font-size: 18px;line-height: 26px;opacity: 0.4;}
/* responsive */
@media only screen and (max-width:1199px){
    .choose-us {padding: 30px 0 0px;}
    .choose-us .choose-data {margin: 0 0 100px;}
}
@media only screen and (max-width:991px){
    .choose-us .choose-data p {font-size: 16px;}
}