.sidebar {background: transparent linear-gradient(86deg, #7E4FDC 0%, #725FEE 100%) 0% 0% no-repeat padding-box;min-width: 280px;padding: 51px 0 0 20px;height: 100vh;position: absolute;top: 0;left: 0;bottom: 0;z-index: 6;overflow: hidden;}
.sidebar ul {overflow-y: auto;height: calc(100% - 122px);overflow-x: hidden;padding: 0 0 90px;}
.sidebar ul::-webkit-scrollbar {width: 5px;}
.sidebar ul::-webkit-scrollbar-track {background-color: #6F60EE;border-radius: 8px;}
.sidebar ul::-webkit-scrollbar-thumb {box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);background: #6779ba;border-radius: 8px;}
.sidebar .logo {margin-bottom: 60px;}
.sidebar ul li {cursor: pointer;margin: 0 0 10px 0;transition: all ease-in-out 0.3s;}
.sidebar ul li a { padding: 12px 22px;height: 68px;align-items: center;display: flex;align-items: center;width: 100%;}
/* .sidebar ul li span{ padding: 12px 22px;height: 68px;align-items: center;display: flex;align-items: center;width: 100%;} */
.sidebar ul li .logout-span{ padding: 12px 22px;height: 68px;align-items: center;}
.transition {transition: all ease-in-out 0.3s;}
.sidebar ul li a:hover, .sidebar ul li .logout-span:hover,
.sidebar ul li a.active, .sidebar ul li .logout-span.active, .sidebar ul li span:hover {background: #401c86;border-top-left-radius: 8px;border-bottom-left-radius: 8px;}
.sidebar ul li span {font: normal normal normal 18px/23px Outfit;color: #FFFFFF;}
.active .sidebar ul li a svg {color: white;}
.sidebar ul li .menu-white-icons{width: 24px;height: 24px;margin-right: 15px;}
.sidebar ul li .menu-white-icons img{width: 100%;height: 100%;object-fit: contain;}
/* responsive */
@media only screen and (max-width:1919px){

}
@media only screen and (max-width:1799px){

}
@media only screen and (max-width:1599px){

}
@media only screen and (max-width:1399px){
    .sidebar .logo {padding: 0px 20px;}
}
@media only screen and (max-width:1199px){
    .sidebar {min-width: 240px;}
    .sidebar ul li a {padding: 12px 16px;height: 55px;}
    .sidebar ul li span {font: normal normal normal 16px/18px Outfit;}
    .sidebar ul li .menu-white-icons {width: 18px;height: 30px;}
    .sidebar .logo {width: 85%;}
}
@media only screen and (max-width:991px){
    .sidebar {position: absolute;left: -245px;transition: all ease-in-out 0.3s;padding: 51px 15px 0 15px;z-index: 99999;}
    .sidebar.show{left: 0;transition: all ease-in-out 0.3s;}
    .sidebar ul li a:hover, .sidebar ul li .logout-span:hover, .sidebar ul li a.active, .sidebar ul li .logout-span.active {border-radius: 8px;}
}
@media only screen and (max-width:767px){

}
@media only screen and (max-width:575px){

}
@media only screen and (max-width:479px){

}
@media only screen and (max-width:319px){
    .sidebar {max-width: 230px;min-width: 230px;}
}