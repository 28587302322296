/* .fullpage-loader-holder {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
}

.fullpage-loader {
  width: 112px;
  height: 112px;
  position: absolute;
  left: 50%;
  top: 50%;
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.fullpage-loader .circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #c92626;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}

.fullpage-loader .circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.fullpage-loader .circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.fullpage-loader .shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.fullpage-loader .shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.fullpage-loader .shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.fullpage-loader span {
  position: absolute;
  top: 75px;
  font-family: "Lato";
  font-size: 20px;
  letter-spacing: 12px;
  color: #11bfe3;
  left: 15%;
} */


.center-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position:fixed;
  left: 0;
  top:0;
  width: 100%;
  height: 100vh;
  background: transparent linear-gradient(241deg, rgba(253, 230, 255, 0.9) 20%, rgba(227, 248, 255, 0.9) 100%) 0% 0% no-repeat padding-box;
  z-index: 9999;
}
.loader-circle-7 {
  position: relative;
  width: 100px;
  height:100px;
  display: inline-block;
}
.loader-circle-7:before,
.loader-circle-7:after {
  content: "";
  display: block;
  position: absolute;
  border-width: 5px;
  border-style: solid;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border-color: #895cea;
  top: 0;
  left: 0;
}
.loader-circle-7:before {
  animation: loader-circle-7-scale 1s linear 0s infinite;
}
.loader-circle-7:after {
  opacity: 0;
  animation: loader-circle-7-scale 1s linear 0.5s infinite;
}
@keyframes loader-circle-7-scale {
  0% {
      transform: scale(0);
      opacity: 0;
  }
  50% {
      transform: scale(0.7);
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 0;
  }
}

/*loader  text*/
/* common */
.loading {
  font-size: 70px;
  font-weight: 600;
  text-align: center;
  color: #895cea;
}
.loading span {
  display: inline-block;
  margin: 0 10px 35px;
}

/* code #1 */
.loading01 span {
  animation: loading01 1.4s infinite alternate;
}
.loading01 span:nth-child(1) {
  animation-delay: 0s;
}
.loading01 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading01 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading01 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading01 span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading01 span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading01 span:nth-child(7) {
  animation-delay: 0.6s;
}

@keyframes loading01 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* code #2 */
.loading02 span {
  animation: loading02 1.2s infinite alternate;
}
.loading02 span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading02 span:nth-child(3) {
  animation-delay: 0.4s;
}
.loading02 span:nth-child(4) {
  animation-delay: 0.6s;
}
.loading02 span:nth-child(5) {
  animation-delay: 0.8s;
}
.loading02 span:nth-child(6) {
  animation-delay: 1s;
}
.loading02 span:nth-child(7) {
  animation-delay: 1.2s;
}

@keyframes loading02 {
  0% {
    filter: blur(0);
    opacity: 1;
  }
  100% {
    filter: blur(5px);
    opacity: 0.2;
  }
}
/* code #3 */
.loading03 span {
  margin: 0 -0.075em;
  animation: loading03 0.7s infinite alternate;
}
.loading03 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading03 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading03 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading03 span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading03 span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading03 span:nth-child(7) {
  animation-delay: 0.6s;
}

@keyframes loading03 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
/* code #4 */
.loading04 span {
  animation: loading04 0.7s infinite;
}
.loading04 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading04 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading04 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading04 span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading04 span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading04 span:nth-child(7) {
  animation-delay: 0.6s;
}

@keyframes loading04 {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
}
/* code #5 */
.loading05 {
  perspective: 1000px;
}
.loading05 span {
  transform-origin: 50% 50% -25px;
  transform-style: preserve-3d;
  animation: loading05 1.6s infinite;
}
.loading05 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading05 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading05 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading05 span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading05 span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading05 span:nth-child(7) {
  animation-delay: 0.6s;
}

@keyframes loading05 {
  0% {
    transform: rotateX(-360deg);
  }
  70% {
    transform: rotateX(0);
  }
}
/* code #6 */
.loading06 span {
  position: relative;
  color: rgba(0, 0, 0, 0.2);
}
.loading06 span::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  color: #fff;
  opacity: 0;
  transform: rotateY(-90deg);
  animation: loading06 4s infinite;
}
.loading06 span:nth-child(2)::after {
  animation-delay: 0.2s;
}
.loading06 span:nth-child(3)::after {
  animation-delay: 0.4s;
}
.loading06 span:nth-child(4)::after {
  animation-delay: 0.6s;
}
.loading06 span:nth-child(5)::after {
  animation-delay: 0.8s;
}
.loading06 span:nth-child(6)::after {
  animation-delay: 1s;
}
.loading06 span:nth-child(7)::after {
  animation-delay: 1.2s;
}

@keyframes loading06 {
  0%, 75%, 100% {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  25%, 50% {
    transform: rotateY(0);
    opacity: 1;
  }
}
/* code #7 */
.loading07 span {
  position: relative;
  color: rgba(0, 0, 0, 0.2);
}
.loading07 span::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  color: #fff;
  opacity: 0;
  transform: scale(1.5);
  animation: loading07 3s infinite;
}
.loading07 span:nth-child(2)::after {
  animation-delay: 0.1s;
}
.loading07 span:nth-child(3)::after {
  animation-delay: 0.2s;
}
.loading07 span:nth-child(4)::after {
  animation-delay: 0.3s;
}
.loading07 span:nth-child(5)::after {
  animation-delay: 0.4s;
}
.loading07 span:nth-child(6)::after {
  animation-delay: 0.5s;
}
.loading07 span:nth-child(7)::after {
  animation-delay: 0.6s;
}

@keyframes loading07 {
  0%, 75%, 100% {
    transform: scale(1.5);
    opacity: 0;
  }
  25%, 50% {
    transform: scale(1);
    opacity: 1;
  }
}
/* code #8 */
.loading08 span {
  position: relative;
  color: rgba(0, 0, 0, 0.2);
  animation: loading08-parent 5s infinite;
}
.loading08 span::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-text);
  color: #fff;
  opacity: 0;
  animation: loading08-child 5s infinite;
}
.loading08 span:nth-child(2)::after {
  animation-delay: 0.2s;
}
.loading08 span:nth-child(3)::after {
  animation-delay: 0.4s;
}
.loading08 span:nth-child(4)::after {
  animation-delay: 0.6s;
}
.loading08 span:nth-child(5)::after {
  animation-delay: 0.8s;
}
.loading08 span:nth-child(6)::after {
  animation-delay: 1s;
}
.loading08 span:nth-child(7)::after {
  animation-delay: 1.2s;
}

@keyframes loading08-parent {
  0%, 35%, 100% {
    color: rgba(0, 0, 0, 0.2);
  }
  60%, 80% {
    color: #fff;
  }
}
@keyframes loading08-child {
  0% {
    opacity: 1;
  }
  25%, 100% {
    opacity: 0;
  }
}


/*media quries*/
@media only screen and (max-width:991px){
  .loading {font-size: 55px;}
}
@media only screen and (max-width:767px){
  .loading {font-size: 40px;}
  .loading span {margin: 0 8px 35px;}
}
@media only screen and (max-width:575px){
  .loading {font-size: 35px;}
  .loader-circle-7 {width: 70px;height: 70px;}
  .loader-circle-7:before, .loader-circle-7:after {width: 70px;height: 70px;}
  .loading span {margin: 0 6px 35px;}
}
@media only screen and (max-width:479px){
  .loading {font-size: 35px;}
  .no-span {display: none;}
}
@media only screen and (max-width:319px){
  .loading {font-size: 30px;}
  .loading span {margin: 0 8px 35px;}
}
