.history-section{min-height: calc(100vh - 60px);background: transparent linear-gradient(241deg, #FDE6FF 20%, #E3F8FF 100%) 0% 0% no-repeat padding-box;padding: 150px 39px 50px;min-width: calc(100% - 280px);}
.history-section .history-details h2 {font-weight: 700;font-size: 36px;line-height: 40px;letter-spacing: 2.88px;margin-bottom: 50px;}
.history-section .history-main{border-radius: 10px;overflow: hidden;box-shadow: 0px 3px 16px #00000029;background: #fff;padding: 30px;}
.history-section .tabs-section .tabs-header {background: #6F60EE;padding: 20px;border-radius: 10px;margin: 0 0 41px;}
.history-section .tabs-section .tabs-header .nav-item{margin: 0 28px;}
.history-section .tabs-section .tabs-header .nav-item .nav-link{color: #fff;padding: 8px 17px;min-width: 82px;text-align: center;}
.history-section .tabs-section .tabs-header .nav-item .nav-link.active, .history-section .tabs-section .tabs-header .nav-item .show>.nav-link{background: #401c86;}
/*responsive*/
@media (max-width: 1599px) {
    .referral-dashboard .history-details h2 {font-size: 33px;line-height: 36px;}
    .history-section .tabs-section .tabs-header .nav-item { margin: 0 16px;}
}
@media (max-width: 1399px) {
    .history-section .tabs-section .tabs-header .nav-item {margin: 0 5px;}
}
@media (max-width: 991px){
    .history-section{padding: 150px 12px 50px;}
}
@media (max-width: 767px){
    .history-section .history-details h2 {font-size: 28px;line-height: 30px;}
}
@media (max-width: 479px){
    .history-section .history-details h2 {font-size: 28px;line-height: 30px;}
    .history-section{padding: 170px 0px 50px;}
}
@media (max-width: 319px) {
    .history-section .history-details h2 {font-size: 26px;line-height: 28px;}
    .history-section .tabs-section .tabs-header {padding: 20px 10px;}
    .history-section .tabs-section .tabs-header .nav-item {margin: 0 0px;}
    .history-section .tabs-section .tabs-header .nav-item .nav-link {font-size: 15px;}
}