.bonus-policy {padding: 85px 0;}
.bonus-policy .small-head{padding-left: 75px;position: relative;}
.bonus-policy .small-head::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);left: 0%;height: 2px;background-color: #725FEE;width: 60px;}
.bonus-policy .bonus-head{margin: 0 0 42px;}
.bonus-policy .bonus-para p{margin: 0 0 42px;}
.bonus-policy .bonus-para p:last-of-type{margin: 0 0 100px;}
.bonus-policy .input-section{max-width: 550px;height: 80px;position: relative;}
.bonus-policy .input-section input {background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 10px 50px #E2282926;border-radius: 20px;width: 100%;height: 100%; padding: 10px 80px 10px 30px;border: none;color: #E22829;font-size: 22px;line-height: 28px;font-weight: 500;}
.bonus-policy .input-section .copy-icon {position: absolute;top: 50%;right: 40px;transform: translateY(-50%);cursor: pointer;}
.bonus-policy .red-data {background-color: #ffebe8;display: inline-block;padding: 20px;border-radius: 10px;border: 1px solid red;color: #000;}
.bonus-policy  .input-label span{font-size: 20px;line-height: 30px;font-weight: bold;}
/* responsive */
@media only screen and (max-width:1399px){
    .bonus-policy .bonus-para p:last-of-type {margin: 0 0 50px;}
}
@media only screen and (max-width:1199px){
    .bonus-policy .bonus-para p {margin: 0 0 30px;}
    .bonus-policy {padding: 60px 0;}
}
@media only screen and (max-width:991px){
    .bonus-policy .policy-img {width: 75%;margin: 0 auto;}
}
@media only screen and (max-width:479px){
    .bonus-policy .input-section input {padding: 10px 45px 10px 15px;}
    .bonus-policy .input-section .copy-icon {right: 13px; width: 6%;}
    .bonus-policy .policy-img {width: 100%;}
    /**/
    .bonus-policy .input-section {height: 60px;}
}
@media only screen and (max-width:319px){
    .bonus-policy .input-section input {font-size: 18px;line-height: 22px;}
}