.referral-dashboard{min-height: calc(100vh - 60px);background: transparent linear-gradient(241deg, #FDE6FF 20%, #E3F8FF 100%) 0% 0% no-repeat padding-box;padding: 150px 39px 50px;min-width: calc(100% - 280px);}
.referral-dashboard .refferal-details h2 {font-weight: 700;font-size: 36px;line-height: 40px;letter-spacing: 2.88px;margin-bottom: 50px;}
.referral-dashboard .referral-tree-main{border-radius: 10px;overflow: hidden;box-shadow: 0px 3px 16px #00000029;}
.referral-dashboard .referral-tree {background: #fff;box-shadow: 0px 3px 0px #00000029;min-height: auto;padding: 30px;display: flex;justify-content: center;align-items: center;}
.referral-dashboard .referral-tree strong {font: normal normal bold 20px/26px Outfit;letter-spacing: 0px;color: #000;display: block;}
.referral-dashboard .referral-tree span {display: block;font: normal normal 500 20px/26px Outfit;letter-spacing: 0px;color: #6D80C4;}
.referral-dashboard .referral-tree .add-items {margin-bottom: 70px;}
.referral-dashboard .referral-tree-structure {background: #6F60EE;padding-bottom: 75px;}
.referral-dashboard .root-line {position: relative;padding-top: 40px;margin: 0 20px;}
.referral-dashboard .root-line::after {position: absolute;content: '';border-bottom: 2px solid #6779ba;top: 0;width: 123%;}
.referral-dashboard .root-line:last-child::after {border: none;}
.referral-dashboard .recieved-bonus {width: 70px;}
.referral-dashboard .referral-tree-structure::-webkit-scrollbar {width: 5px ;}
.referral-dashboard .referral-tree-structure::-webkit-scrollbar-track {background-color: #fff ;border-radius: 8px ;}
.referral-dashboard .referral-tree-structure::-webkit-scrollbar-thumb {box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) ;background: #6779ba ;border-radius: 8px ;}
.referral-dashboard .root-line::before {content: '';position: absolute;border-bottom: 2px solid #6779ba;width: 20px;top: 8px;left: 46%;transform: rotate(90deg);}
.referral-dashboard .referral-tree-structure {overflow: auto;}
.referral-dashboard .referral-link {background: #fff;box-shadow: 0px 3px 16px #00000029;min-height: auto;margin-bottom: 72px;position: relative;border-radius: 10px;overflow: hidden;}
/* .referral-dashboard .referral-link .user-details {position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);text-align: center;} */
.referral-dashboard .referral-link .body-content {padding: 40px 0px;text-align: center;}
.referral-dashboard .referral-link .body-content .refer-tag{margin: 0 0 20px;}
.referral-dashboard .referral-link .body-content .refer-user strong{display: block;margin: 0 0 10px;}
.referral-dashboard .referral-link .body-content .refer-user span {font-size: 20px;line-height: 30px;}
.referral-dashboard .referral-link .user-details strong {font: normal normal bold 24px/32px Outfit;letter-spacing: 0px;}
.referral-dashboard .referral-link .header-top {background: #6F60EE 0% 0% no-repeat padding-box;min-height: auto; padding: 50px 70px;}
.referral-dashboard .referral-link .bottom-footer {background: #6F60EE 0% 0% no-repeat padding-box;min-height: 96px;padding: 23px 30px;}
/* .referral-dashboard .referral-link .body-content {min-height: 230px;} */
.referral-dashboard .referral-link .bottom-footer strong {font: normal normal bold 20px/26px Outfit;letter-spacing: 0px;color: #FFFFFF;display: block;margin-bottom: 7px;}
.referral-dashboard .referral-link .bottom-footer a {cursor: pointer;font: normal normal normal 14px/18px Outfit;text-decoration: none;letter-spacing: 0px;color: #FFFFFF;display: block;}
.referral-dashboard .referral-link .header-top p {font: normal normal bold 24px/32px Outfit;letter-spacing: 0px;color: #FFFFFF;}
.referral-dashboard .referral-link .header-top strong {font: normal normal bold 40px/52px Outfit;letter-spacing: 0px;color: #FFFFFF;}
.referral-dashboard .user-details .user-img {width: 212px;height: 212px;border-radius: 100%;    border: 10px solid #6d80c4;margin: 0 auto 15px;box-shadow: 0px 3px 16px #00000029;}
.referral-dashboard .user-details .user-img img{width: 100%; height: 100%; object-fit: cover;}
.referral-dashboard .referral-link .header-top span {font: normal normal 300 14px/18px Outfit;letter-spacing: 0px;color: #FFFFFF;padding: 5px;}
.referral-dashboard .referral-link .header-top .transparent-btn span{color: inherit;}
.referral-dashboard .referral-link .header-top .transparent-btn:hover {color: #fff;background: #fff;border-color: #725FEE;box-shadow: 0px 0px 2px #fff;}
.referral-dashboard .arrow-down {position: relative;}
.referral-dashboard .arrow-down::after {position: absolute;height: 75px;width: 4px;border-right: 3px solid #6D80C4;content: '';}
.referral-dashboard .arrow-down::before {border: 3px solid #6D80C4;content: '';border-width: 0 3px 3px 0;display: inline-block;padding: 3px;transform: rotate(45deg);-webkit-transform: rotate(45deg);bottom: -77px;/* left: 121px; */left: 84px;position: absolute;}
/*responsive*/
@media (max-width: 1900px) {
    .referral-dashboard .arrow-down::before { left: 84px;}
}
@media (max-width: 1599px) {
    .referral-dashboard .refferal-details h2 {font-size: 33px;line-height: 36px;}
}
@media (max-width: 1399px) {
    .referral-dashboard .referral-link .user-details{top: 44%;}
}
@media (max-width: 991px){
    .referral-dashboard{padding: 150px 12px 50px;}
}
@media (max-width: 575.98px) {
    .referral-dashboard .referral-link .user-details{top: 50%;}
}
@media (max-width: 479px){
    .referral-dashboard .refferal-details h2 {font-size: 28px;line-height: 30px;}
    .referral-dashboard{padding: 170px 0px 50px;}
    .referral-dashboard .referral-link .header-top{text-align: center;}
    .referral-dashboard .referral-link .header-top > div {flex-direction: column;}
    .referral-dashboard .referral-link .bottom-footer {padding: 23px 12px;}
    .referral-dashboard .referral-link .header-top .your-referral{justify-content: center;}
    .referral-dashboard .referral-link .bottom-footer .link{font-size: 14px;}
}
@media (max-width: 360px) {
    .referral-dashboard .referral-link .user-details{top: 38%;}
    .referral-dashboard .arrow-down::before {left: 63px;}
    .referral-dashboard .referral-link .header-top {padding: 10px;}
    .referral-dashboard .referral-link .header-top p {font-size: 15px;line-height: 19px;}
    .referral-dashboard .referral-link .header-top strong {font-size: 30px;}
    .referral-dashboard .referral-link .header-top span {font-size: 12px;}
    .referral-dashboard .referral-link .bottom-footer strong {font: normal normal bold 14px/26px Outfit;}
    .referral-dashboard .referral-link .bottom-footer a {font: normal normal normal 8px/18px Outfit;}
    .referral-dashboard .referral-link .user-details strong {font-size: 20px;line-height: 24px;}
    .referral-dashboard .referral-tree strong,
    .referral-dashboard .referral-tree span {font-size: 15px;}
}
@media (max-width: 319px) {
    .referral-dashboard .referral-tree strong,
    .referral-dashboard .referral-tree span {font-size: 12px;}
    .referral-dashboard .arrow-down::before {left: 50px;}
    .referral-dashboard .refferal-details h2 {font-size: 26px;line-height: 28px;}
}