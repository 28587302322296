.terms-conditions{padding: 104px 0px;}
.terms-conditions p{opacity: 1 !important;}
.terms-conditions a{color: #6F60EE;font-size: 22px;}
.terms-conditions .text-emphasized {font-size: 34px;line-height: 38px;color: #6F60EE;margin: 30px 0 40px;}
.terms-conditions .text-theme {color: #E22829;}
.terms-conditions .list-unstyled {padding-left: revert;list-style: none;}
.terms-conditions .list-unstyled li {margin: 0 0 20px;position: relative;}
.terms-conditions .list-unstyled li::before{content: url('../../assets/images/favicon-16x16.png');position: absolute;font-size: 16px;line-height: 16px;left: -30px;top: 5px;}
/* responsive */
@media only screen and (max-width:1399px){
    .terms-conditions .text-emphasized {font-size: 30px;line-height: 32px;margin: 26px 0 35px;}
}
@media only screen and (max-width:991px){
    .terms-conditions .terms-img {width: 75%;margin: 0 auto 50px;}
}
@media only screen and (max-width:767px){
    .terms-conditions .text-emphasized {font-size: 26px;line-height: 28px;}
}
@media only screen and (max-width:575px){
    .terms-conditions a {font-size: 18px;}
}
@media only screen and (max-width:479px){
    .terms-conditions {padding: 0px 0px 104px;}
    .terms-conditions .text-emphasized {font-size: 22px;line-height: 28px;}
    .terms-img.text-center {width: 100%;}
    .terms-conditions .list-unstyled li {margin: 0 0 12px;}
}