.how-it-works{padding: 200px 0px;}
.how-it-works .hiw-images{text-align: end;position: relative;}
.how-it-works .hiw-images .top-img {position: absolute;left: 0;top: -50%;transform: translateY(50%);transition: all ease-in-out 0.3s;}
.how-it-works .hiw-images .bottom-img {position: absolute;left: 0;bottom: -50%;transform: translateY(-50%);transition: all ease-in-out 0.3s;}
.how-it-works .hiw-images .top-img.hoverTop{top: -39%;transition: all ease-in-out 0.3s;}
.how-it-works .hiw-images .bottom-img.hoverBottom{left: 10%;bottom: -39%;transition: all ease-in-out 0.3s;}
.how-it-works .hiw-data .hiw-head{margin: 0 0 76px;}
.how-it-works .small-head{padding-left: 75px;position: relative;}
.how-it-works .small-head::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);left: 0%;height: 2px;background-color: #725FEE;width: 60px;}
.how-it-works .hiw-content .inner-content{margin: 0 0 63px;}
.how-it-works .hiw-content .inner-content .inner-content-img{width: 98px;height: 98px;border-radius: 50%;box-shadow: 0px 3px 26px #3524A524;overflow: hidden;background-color: #fff;margin-right: 38px;display: flex;justify-content: center;align-items: center;}
.how-it-works .hiw-content .inner-content .inner-content-data{width: calc(100% - 136px);}
.how-it-works .hiw-content .inner-content .inner-content-data h3{font-size: 24px;line-height: 30px;font-weight: bold;margin: 0 0 15px;}
.how-it-works .hiw-content .inner-content .inner-content-data p{font-size: 18px;line-height: 30px;font-weight: normal;opacity: 0.4;margin: 0;}
/* responsive */
@media only screen and (max-width:1399px){
    .how-it-works {padding: 100px 0px;}
    .how-it-works .hiw-images {position: relative;display: flex;align-items: center;}
    .how-it-works .hiw-images .center-img {height: 600px;}
}
@media only screen and (max-width:1199px){
    .how-it-works .hiw-images .center-img img{height: 100%;}
}
@media only screen and (max-width:991px){
    .how-it-works .hiw-images {justify-content: end;margin: 0 0 140px;}
}
@media only screen and (max-width:479px){
    .how-it-works .hiw-images .center-img {height: 400px;}
    .how-it-works .hiw-images .top-img, .how-it-works .hiw-images .top-img.hoverTop {top: -60%;}
    .how-it-works .hiw-images .bottom-img, .how-it-works .hiw-images .bottom-img.hoverBottom { left: 0%;bottom: -63%;}
    .how-it-works .hiw-content .inner-content {flex-direction: column;}
    .how-it-works .hiw-content .inner-content .inner-content-img {margin: 0 0 20px;}
    .how-it-works .hiw-content .inner-content .inner-content-data {width: 100%;text-align: center;}
    .how-it-works .hiw-data .hiw-head { margin: 0 0 50px;}
}
@media only screen and (max-width:319px){
    .how-it-works .hiw-content .inner-content .inner-content-data h3 {font-size: 20px;line-height: 28px;}
    .how-it-works .hiw-images .center-img {height: 350px;}
    .how-it-works .hiw-content .inner-content .inner-content-data p {font-size: 16px;}
}