.reset-section .reset-header{padding: 40px 0px;position: absolute; width: 100%;top: 0;left: 0;z-index: 99;}
.reset-section .reset-header .logo{width: 173px;}
.reset-section {background-image: url('../../assets/images/login-bg.jpg');background-repeat: no-repeat;background-size: 100% 100%;height: 100vh;}
.reset-section .main-section {height: 100%;}
.reset-section .reset-lottie-in{max-width: 500px;height: 500px;overflow: hidden;}
.reset-section .reset-data .reset-data-in{    width: 100%;    max-width: 686px;    margin: 0 0 0 auto;}
.reset-section .reset-data .reset-data-in .heading{display: block;font-size: 42px;line-height: 53px;font-weight: bold;margin: 0 0 70px;}
.reset-section .reset-data .reset-data-in .reset-form .input-section{margin: 0 0 30px;}
.reset-section .reset-data .reset-data-in .reset-form .input-section.last-section{margin: 0 0 50px;}
.reset-section .reset-data .reset-data-in .reset-form .input-section .data-label{font-size: 20px;line-height: 25px;font-weight: bold;margin: 0 0 15px;}
.reset-section .reset-data .reset-data-in .reset-form .input-section input{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 26px #3524A524;border-radius: 4px;height: 65px;padding: 10px 20px;border: none;}
.reset-section .reset-data .reset-data-in .reset-form .input-section .relative-input{position: relative;}
.reset-section .reset-data .reset-data-in .reset-form .input-section .relative-input .eye-icon{position: absolute;top: 50%;transform: translateY(-50%);right: 20px;color: #989898;cursor: pointer;}
.reset-section .reset-data .reset-data-in .reset-form .input-section .relative-input .eye-icon.active{color: #725FEE;}
.reset-section .reset-data .reset-data-in .reset-form .input-section input:focus, .reset-section .reset-data .reset-data-in .reset-form .input-section input:focus-visible{outline: none;box-shadow: none;}
.reset-section .reset-data .reset-data-in .reset-form .reset-button{margin: 0 0 27px;}
.reset-section .reset-data .reset-data-in .reset-form .reset-button .reset-pass{font-size: 18px;line-height: 23px;color: #09091A;}
.reset-section .reset-data .reset-data-in .reset-form .reset-button{margin: 0 0 30px;}
.reset-section .reset-data .reset-data-in .signup-button{font-size: 18px;line-height: 23px;}
.reset-section .reset-data .reset-data-in .signup-button-in .signup-text{font-weight: normal;}
.reset-section .reset-data .reset-data-in .signup-button-in .signup-button{font-weight: bold;color: #725FEE;}
.reset-section .reset-data .reset-data-in .reset-form .input-section input[type="password"]{padding: 10px 50px 10px 20px;}
/*overflow scroll*/
.reset-section::-webkit-scrollbar {background-color: #fff; width: 10px;}
.reset-section::-webkit-scrollbar-button { background-color: #fff; }
.reset-section::-webkit-scrollbar-track  { background-color:#fff; }
.reset-section::-webkit-scrollbar-track-piece  { background-color: #fff;}
.reset-section::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box; }
.reset-section::-webkit-scrollbar-corner { background-color:#fff;  }
.reset-section::-webkit-resizer {background-color:#fff; }
.reset-section{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;}
/* responsive */
@media only screen and (max-width:1799px){
    .reset-section .reset-data .reset-data-in .heading {font-size: 38px;line-height: 48px;margin: 0 0 55px;}
    .reset-section .reset-lottie-in {height: 550px;}
}
@media only screen and (max-width:1599px){
    .reset-section .reset-data .reset-data-in .heading {font-size: 32px;line-height: 42px;margin: 0 0 35px;}
    .reset-section .reset-data .reset-data-in .reset-form .input-section input {height: 55px;}
}
@media only screen and (max-width:1399px){
    .reset-section .reset-lottie-in {height: 480px;}
    .reset-section .reset-data .reset-data-in .reset-form .input-section input {height: 45px;}
    .reset-section .reset-header .logo {width: 128px;display: block;}
    .reset-section .reset-data .reset-data-in .reset-form .input-section {margin: 0 0 10px;}
    .reset-section .reset-data .reset-data-in .reset-form .input-section .data-label {font-size: 16px;line-height: 20px;}
    .reset-section .reset-data .reset-data-in .reset-form .reset-button .reset-pass {font-size: 16px;line-height: 20px;}
    .reset-section .purple-border-btn {height: 45px;}
}
@media only screen and (max-width:1199px){
    .reset-section .reset-data .reset-data-in .heading {font-size: 28px;line-height: 38px;margin: 0 0 25px;}
}
@media only screen and (max-width:991px){
    .reset-section .reset-data .reset-data-in {padding: 0px 0px;overflow: visible;}
    .reset-section .reset-data .reset-data-in {max-height: none;margin: 0 0 30px;}
    .reset-section{overflow-y: auto;}
    .reset-section .reset-header {padding: 0;position: relative;height: 90px;display: flex;align-items: center;}
    .reset-section .main-section {height: calc(100% - 90px);}
}
@media only screen and (max-width:575px){
    .reset-section .reset-data .reset-data-in .heading {font-size: 24px;line-height: 34px;}
}