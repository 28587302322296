.packages-tab{min-height: calc(100vh - 60px);background: transparent linear-gradient(241deg, #FDE6FF 20%, #E3F8FF 100%) 0% 0% no-repeat padding-box;padding: 150px 39px 50px;min-width: calc(100% - 280px);}
.packages-tab .package-details h2 {font-weight: 700;font-size: 36px;line-height: 40px;letter-spacing: 2.88px;margin-bottom: 150px;}
.packages-tab .selected-package { margin: 0 0 70px;}
.packages-tab .packages-data{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 5px 50px #0203070D;border-radius: 14px;width: 100%;padding: 136px 40px 53px;position: relative;text-align: center;margin: 0 0 100px; min-height: 595px;}
.packages-tab .packages-data h3{font-size: 48px;line-height: 60px;text-transform: uppercase;margin: 0 0 4px;font-weight: 900;display: -webkit-box;max-width: 100%;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;max-height: 120px;}
.packages-tab .packages-data .span-text{font-size: 24px;line-height: 30px;margin: 0 0 30px;display: flex;align-items: center;justify-content: center;}
.packages-tab .packages-data p{font-size: 18px;line-height: 26px;margin: 0 0 20px;opacity: 0.7;display: -webkit-box;max-width: 100%;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 3;max-height: 80px;}
.packages-tab .packages-data .min-limit{max-width: calc(100% - 225px);text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.packages-tab .packages-data .min-limit-1{max-width: calc(100% - 72px);text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.packages-tab .packages-data .months{margin-left: 10px;width: 215px;}
/* .packages-tab .packages-data .currency{margin-left: 10px;width: 62px;} */
.packages-tab .packages-data button{width: 199px;}
.packages-tab .packages-data .lottie-cover{width: 150px;height: 150px;border-radius: 50%;margin: 0 auto;box-shadow: 0px 5px 50px #0203070D;position: absolute;left: 50%;transform: translateX(-50%);top: -75px;z-index: 1;overflow: hidden;background-color: #fff;}
.packages-tab .packages-data .lottie-cover svg{width: 200px !important;height: 200px !important;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%) !important;}
.packages-tab .packages-data .red-img{position: absolute;top: -4px;left: 50px;text-align: center;}
.packages-tab .packages-data .red-img .percentage {position: absolute;top: 5px;left: 50%;transform: translateX(-50%);font-size: 20px;font-weight: bold;color: #fff;}
.packages-tab .packages-data .red-img .reward-tag{position: absolute;top: 26px;left: 50%;transform: translateX(-50%);font-size: 16px;color: #fff;}
/*responsive*/
@media (max-width: 1799px) {
    .packages-tab .packages-data {padding: 115px 20px 53px;}
    .packages-tab .packages-data h3 {font-size: 38px;line-height: 50px;}
    .packages-tab .packages-data .lottie-cover {width: 115px;height: 115px;top: -55px;}
    .packages-tab .packages-data .red-img {left: 30px;}
}
@media (max-width: 1599px) {
    .packages-tab .package-details h2 {font-size: 33px;line-height: 36px;}
    .packages-tab .packages-data .lottie-cover {width: 95px;height: 95px;}
    .packages-tab .packages-data .lottie-cover svg{width: 150px !important;height: 150px !important;}
    .packages-tab .packages-data .red-img{left: 20px;width: 75px;}
    .packages-tab .packages-data{padding: 90px 20px 40px;}
    .packages-tab .packages-data button{width: 160px;}
    .packages-tab .package-details h3 {font-size: 28px;line-height: 30px;}
    /*new*/
    .packages-tab .packages-data {min-height: 470px;}
}
@media (max-width: 1399px) {
    .packages-tab .package-details h2 {margin-bottom: 120px;}
    .packages-tab .packages-data {margin: 0 auto 90px;}
    .packages-tab .selected-package {margin: 0 0 0px;}
}
@media (max-width: 1199px){
    .packages-tab .packages-data p {font-size: 15px;}
}
@media (max-width: 991px){
    .packages-tab{padding: 150px 12px 50px;}
    .packages-tab {padding: 150px 12px 0px;}
    /**/
    .packages-tab .packages-data {margin: 0 auto 90px;width: 80%;}
}
@media (max-width: 767px){
    .packages-tab .packages-data {width: 90%;}
}
@media (max-width: 575px){
    .packages-tab .packages-data {width: 100%;}
}
@media (max-width: 479px){
    .packages-tab .package-details h2 {font-size: 28px;line-height: 30px;}
    .packages-tab{padding: 170px 0px 50px;}
    .packages-tab .packages-data .red-img {left: 20px;width: 60px;}
    .packages-tab .packages-data .red-img .percentage {top: 3px;font-size: 15px;}
    .packages-tab .packages-data .red-img .reward-tag {top: 20px;font-size: 14px;}
    .packages-tab .packages-data .span-text {font-size: 20px;line-height: 26px;margin: 0 0 20px;}
    /*new*/
    .packages-tab .packages-data .span-text {font-size: 17px;line-height: 26px;}
    .packages-tab .packages-data .months {width: 135px;margin-left: 5px;}
    .packages-tab .packages-data .min-limit { max-width: calc(100% - 140px);}
    /* .packages-tab .packages-data .currency { width: 55px;} */
    .packages-tab .packages-data .min-limit-1 { max-width: calc(100% - 65px);}
}
@media (max-width: 319px) {
    .packages-tab .package-details h2 {font-size: 26px;line-height: 28px;}
    .packages-tab .packages-data .lottie-cover {width: 85px;height: 85px;top: -40px;}
    .packages-tab .packages-data .red-img {left: 13px;width: 56px;}
    .packages-tab .packages-data .red-img .reward-tag {top: 18px;font-size: 12px;}
    /**/
    .packages-tab .packages-data .months { width: 150px;}
}