.signup-section .signup-header{padding: 40px 0px;position: absolute; left: 110px;top: 0;z-index: 99;}
.signup-section .signup-header .logo{width: 173px;}
.signup-section {background-image: url('../../assets/images/login-bg.jpg');background-repeat: no-repeat;background-size: 100% 100%;height: 100vh;padding:0 0 30px;overflow-y: auto;}
/* .signup-section .main-section {height: 100%;} */
.signup-section .signup-lottie-in{max-width: 538px;height: 557px;overflow: hidden;}
.signup-section .signup-data .signup-data-in{width: 100%; max-width: 706px;margin: 0 0 0 auto;padding: 150px 20px 50px 5px;}
.signup-section .signup-data .signup-data-in .heading{display: block;font-size: 42px;line-height: 53px;font-weight: bold;margin: 0 0 70px;}
.signup-section .signup-data .signup-data-in .signup-form .input-section{margin: 0 0 30px;}
.signup-section .signup-data .signup-data-in .signup-form .input-section.last-section{margin: 0 0 60px;}
.signup-section .signup-data .signup-data-in .signup-form .input-section .data-label{font-size: 20px;line-height: 25px;font-weight: bold;margin: 0 0 15px;}
.signup-section .signup-data .signup-data-in .signup-form .input-section input{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 26px #3524A524;border-radius: 4px;height: 65px;padding: 10px 20px;border: none;}
.signup-section .signup-data .signup-data-in .signup-form .input-section .relative-input{position: relative;}
.signup-section .signup-data .signup-data-in .signup-form .input-section .relative-input .eye-icon{position: absolute;top: 50%;transform: translateY(-50%);right: 20px;color: #989898;cursor: pointer;}
.signup-section .signup-data .signup-data-in .signup-form .input-section .relative-input .eye-icon.active{color: #725FEE;}
.signup-section .signup-data .signup-data-in .signup-form .input-section input:focus, .signup-section .signup-data .signup-data-in .signup-form .input-section input:focus-visible{outline: none;box-shadow: none;}
.signup-section .signup-data .signup-data-in .signup-form .signup-button{margin: 0 0 30px;}
.signup-section .signup-data .signup-data-in .signup-button{font-size: 18px;line-height: 23px;}
.signup-section .signup-data .signup-data-in .signup-button-in .signup-text{font-weight: normal;}
.signup-section .signup-data .signup-data-in .signup-button-in .signup-link{font-weight: bold;color: #725FEE;}
/**/
.signup-section .signup-data .signup-data-in .signup-form .input-section input[type="password"]{padding: 10px 50px 10px 20px;}
/*overflow scroll*/
.signup-section .signup-data .signup-data-in::-webkit-scrollbar {background-color: #fff; width: 10px;}
.signup-section .signup-data .signup-data-in::-webkit-scrollbar-button { background-color: #fff; }
.signup-section .signup-data .signup-data-in::-webkit-scrollbar-track  { background-color:#fff; }
.signup-section .signup-data .signup-data-in::-webkit-scrollbar-track-piece  { background-color: #fff;}
.signup-section .signup-data .signup-data-in::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box; }
.signup-section .signup-data .signup-data-in::-webkit-scrollbar-corner { background-color:#fff;  }
.signup-section .signup-data .signup-data-in::-webkit-resizer {background-color:#fff; }
.signup-section .signup-data .signup-data-in{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;}
/*overflow scroll*/
.signup-section::-webkit-scrollbar {background-color: #fff; width: 10px;}
.signup-section::-webkit-scrollbar-button { background-color: #fff; }
.signup-section::-webkit-scrollbar-track  { background-color:#fff; }
.signup-section::-webkit-scrollbar-track-piece  { background-color: #fff;}
.signup-section::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box; }
.signup-section::-webkit-scrollbar-corner { background-color:#fff;  }
.signup-section::-webkit-resizer {background-color:#fff; }
.signup-section{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;}
/* responsive */
@media only screen and (max-width:1799px){
    .signup-section .signup-data .signup-data-in .heading {font-size: 38px;line-height: 48px;margin: 0 0 55px;}
    .signup-section .signup-lottie-in {height: 450px;}
    /* .signup-section .signup-data .signup-data-in {max-height: 622px;} */
}
@media only screen and (max-width:1599px){
    .signup-section .signup-data .signup-data-in .heading {font-size: 32px;line-height: 42px;margin: 0 0 35px;}
    .signup-section .signup-data .signup-data-in .signup-form .input-section input {height: 55px;}
    /* .signup-section .signup-data .signup-data-in {max-height: 550px;} */
}
@media only screen and (max-width:1399px){
    .signup-section .signup-data .signup-data-in .signup-form .input-section input {height: 45px;}
    .signup-section .signup-header .logo {width: 128px;display: block;}
    .signup-section .signup-lottie-in {height: 350px;}
    /* .signup-section .signup-data .signup-data-in {max-height: 410px;} */
    .signup-section .signup-data .signup-data-in .signup-form .input-section {margin: 0 0 10px;}
    .signup-section .signup-data .signup-data-in .signup-form .input-section .data-label {font-size: 16px;line-height: 20px;}
    .signup-section .purple-border-btn {height: 45px;}
}
@media only screen and (max-width:1199px){
    .signup-section .signup-data .signup-data-in .heading {font-size: 28px;line-height: 38px;margin: 0 0 25px;}
    /* .signup-section .signup-data .signup-data-in {max-height: 336px;} */
}
@media only screen and (max-width:991px){
    .signup-section .signup-data .signup-data-in {padding: 50px 0px;overflow: visible;}
    .signup-section .signup-data .signup-data-in {max-height: none;margin: 0 0 30px;}
    .signup-section{overflow-y: auto;}
    .signup-section .signup-header {padding: 0;position: relative;height: 90px;display: flex;align-items: center;}
    .signup-section .main-section {height: calc(100% - 90px);}
}
@media only screen and (max-width:575px){
    .signup-section .signup-data .signup-data-in .heading {font-size: 24px;line-height: 34px;}
}