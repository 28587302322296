.faqs .faq-section .faq-header{background-image: url("../../assets/images/main-banner.bg.png");background-repeat: no-repeat;background-size: 100% 100%;padding: 220px 0;}
.faqs .faq-section .category-tag {background: #0F3179 0% 0% no-repeat padding-box;box-shadow: 0px 3px 16px #00000029;border: 1px solid #56D0F7;width: 229px;min-height: 80px;transition: all ease-in-out 0.3s;}
.faqs .faq-section .category-tag:hover {background-color: #56D0F7;}
.faqs .faq-section .headings {margin-bottom: 40px;}
.faqs .faq-section .headings h2{margin: 0 0 29px;}
.faqs .faq-section .headings p{margin: 0 0 40px;}
.faqs .faq-section a{display: inline-flex; justify-content: center; align-items: center;min-width: 141px;}
.faqs .faq-section .accordion-button{align-items: baseline ;}
.faqs .faq-section .nav-pills .nav-link {background: #725FEE 0% 0% no-repeat padding-box;box-shadow: 0px 3px 16px #00000029;border: 1px solid #fff;/* width: 229px; */padding: 19px 56px ;min-height: 80px;transition: all ease-in-out 0.3s;margin: 8px;display: flex;justify-content: center;align-items: center;font: normal normal bold 24px/21px Assistant ;letter-spacing: 0px;color: #FFFFFF ;border-radius: 4px;}
.faqs .faq-section .nav-pills .nav-link.active,
.faqs .faq-section .nav-pills .show>.nav-link {background-color: #56D0F7;}
.faqs .faq-section .faq-accordion-section {padding: 104px 0;}
.faqs .faq-section .general-faqs .accordion-button,
.faqs .faq-section .general-faqs .accordion-body {background: #725FEE 0% 0% no-repeat padding-box ;box-shadow: 0px 3px 6px #00000029 ;/* border-radius: 5px ; */box-shadow: none ;}
.faqs .faq-section .general-faqs .accordion-button {font: normal normal bold 26px/46px Assistant;letter-spacing: 0px;color: #FFFFFF;}
.faqs .faq-section .general-faqs .accordion-body p {color: #fff;opacity: 1;}
.faqs .faq-section .general-faqs .accordion-button::after {background-image: none;content: "\002B";font-size: 40px;position: absolute; right: 30px;}
.faqs .faq-section .general-faqs .accordion-button:not(.collapsed) {color: #fff ;}
.faqs .faq-section .general-faqs .--bs-accordion-bg {background-color: transparent ;}
.faqs .faq-section .general-faqs .accordion-item {border: none;margin-bottom: 37px;    border-radius: 4px; overflow: hidden;}
.faqs .faq-section .general-faqs .accordion {--bs-accordion-color: #000;--bs-accordion-bg: transparent;}
.faqs .faq-section .general-faqs .accordion-button:not(.collapsed)::after {background-image: none;transform: rotate(45deg);font-size: 40px; right: 20px;}
.faqs .faq-section .general-faqs {padding-top: 83px;width: 1213px;padding-bottom:80px ;}
.faqs .faq-section .general-faqs .accordion-item:first-of-type .accordion-button{border-top-left-radius: 0px; border-top-right-radius: 0px;}  
.faqs .faq-section .general-faqs .accordion-item:last-of-type .accordion-button{border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;} 
.faqs .faq-section .general-faqs .accordion strong{ color: #fff !important;}
/* responsive */
@media only screen and (max-width:1399px){
    .faqs .faq-section .faq-image {width: 75%;margin: 0 auto;}
}
@media only screen and (max-width:1199px){
    .faqs .faq-section .general-faqs .accordion-button {font: normal normal bold 22px/42px Assistant;}
    .faqs .faq-section .nav-pills .nav-link {padding: 14px 47px;font-size: 22px;line-height: 24px;}
    .faqs .faq-section .general-faqs .accordion-button::after {font-size: 30px;}
    .faqs .faq-section .general-faqs .accordion-button:not(.collapsed)::after{font-size: 30px;}
}
@media only screen and (max-width:991px){
    .faqs .faq-section .faq-image {width: 65%;margin: 0 auto 50px;}
}
@media only screen and (max-width:575px){
    .faqs .faq-section .general-faqs .accordion-button {font: normal normal bold 18px/30px Assistant;}
}
@media only screen and (max-width:479px){
    .faqs .faq-section .faq-image {width: 100%;}
    .faqs .faq-section .faq-header {background-size: cover;background-position: center;}
    .faqs .faq-section .general-faqs .accordion-button::after {font-size: 25px;position: absolute;right: 3px;}
    .faqs .faq-section .general-faqs .accordion-button {padding: 10px 30px 10px 15px;}
    .faqs .faq-section .nav-pills .nav-link {padding: 11px 44px;font-size: 20px;line-height: 22px;min-height: 60px;}
    .faqs .faq-section .faq-accordion-section {padding: 0px 0 70px;}
}