/*font families*/
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-Medium.woff2') format('woff2'),    url('./assets/fonts/Outfit-Medium.woff') format('woff');font-weight: 500;font-style: normal;font-display: swap;}
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-Regular.woff2') format('woff2'),    url('./assets/fonts/Outfit-Regular.woff') format('woff');font-weight: normal;font-style: normal;font-display: swap;}
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-Black.woff2') format('woff2'),    url('./assets/fonts/Outfit-Black.woff') format('woff');font-weight: 900;font-style: normal;font-display: swap;}
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-Bold.woff2') format('woff2'),    url('./assets/fonts/Outfit-Bold.woff') format('woff');font-weight: bold;font-style: normal;font-display: swap;}
/*min-widths*/
@media (min-width: 1800px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {max-width: 1666px !important;}
}
@media only screen and (max-width: 1799px) and (min-width: 1600px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {max-width: 1500px !important;}
}
/*webkit scroll*/
/*html scroll*/
html::-webkit-scrollbar {background-color: #fff; width: 10px;}
html::-webkit-scrollbar-button { background-color: #fff; }
html::-webkit-scrollbar-track  { background-color:#fff; }
html::-webkit-scrollbar-track-piece  { background-color: #fff;}
html::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box; border-radius: 20px;}
html::-webkit-scrollbar-corner { background-color:#fff;  }
html::-webkit-resizer {background-color:#fff; }
html{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;}
/*table responsive*/
.table-responsive::-webkit-scrollbar {background-color: #fff; height: 7px;;}
.table-responsive::-webkit-scrollbar-button { background-color: #fff; }
.table-responsive::-webkit-scrollbar-track  { background-color:#fff; }
.table-responsive::-webkit-scrollbar-track-piece  { background-color: #fff;}
.table-responsive::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box;  border-radius: 20px;}
.table-responsive::-webkit-scrollbar-corner { background-color:#fff;  }
.table-responsive::-webkit-resizer {background-color:#fff; }
.table-responsive{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;}
/*html scroll*/
textarea::-webkit-scrollbar {background-color: #fff; width: 10px;}
textarea::-webkit-scrollbar-button { background-color: #fff; }
textarea::-webkit-scrollbar-track  { background-color:#fff; }
textarea::-webkit-scrollbar-track-piece  { background-color: #fff;}
textarea::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box;  border-radius: 20px;}
textarea::-webkit-scrollbar-corner { background-color:#fff;  }
textarea::-webkit-resizer {background-color:#fff; }
textarea{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;resize: none;}
/*scroll*/
.complete-content::-webkit-scrollbar {background-color: #fff; width: 10px;}
.complete-content::-webkit-scrollbar-button { background-color: #fff; }
.complete-content::-webkit-scrollbar-track  { background-color:#fff; }
.complete-content::-webkit-scrollbar-track-piece  { background-color: #fff;}
.complete-content::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box;  border-radius: 20px;}
.complete-content::-webkit-scrollbar-corner { background-color:#fff;  }
.complete-content::-webkit-resizer {background-color:#fff; }
.complete-content{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;resize: none;}
/*scroll*/
.modal::-webkit-scrollbar {background-color: #fff; width: 10px;}
.modal::-webkit-scrollbar-button { background-color: #fff; }
.modal::-webkit-scrollbar-track  { background-color:#fff; }
.modal::-webkit-scrollbar-track-piece  { background-color: #fff;}
.modal::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box; border-radius: 20px; }
.modal::-webkit-scrollbar-corner { background-color:#fff;  }
.modal::-webkit-resizer {background-color:#fff; }
.modal{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;resize: none;}
/**/
.cms-page .table::-webkit-scrollbar {background-color: #fff; width: 10px;}
.cms-page .table::-webkit-scrollbar-button { background-color: #fff; }
.cms-page .table::-webkit-scrollbar-track  { background-color:#fff; }
.cms-page .table::-webkit-scrollbar-track-piece  { background-color: #fff;}
.cms-page .table::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box; border-radius: 20px; }
.cms-page .table::-webkit-scrollbar-corner { background-color:#fff;  }
.cms-page .table::-webkit-resizer {background-color:#fff; }
.cms-page .table{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;resize: none;}
/*basic codes*/
body{color: #09091A;font-family: 'Outfit';}
a{text-decoration: none;}
strong{color: #725FEE;}
.red{color: #E22829;}
.green{color: #12DC77;}
.gold{color: #FF8E00;}
.silver{color: #A6A6A6;}
.bronze{color: #BF6332;}
.cursor-pointer{cursor: pointer;}
.word-break{word-break: break-word !important;}
.purple-bg{background: transparent linear-gradient(86deg, #8758E5 0%, #6F60EE 100%) 0% 0% no-repeat padding-box !important;}
button:focus{box-shadow:none}
/**/
input:focus, textarea:focus{ outline: rgba(111, 96, 238, 0.5) solid 2px !important;}
/**/
.no-border{border: none !important;}
/**/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
		caret-color: #09091A; 
		-webkit-text-fill-color: #09091A !important;
		-webkit-box-shadow: 0 0 0px 1000px #fff  inset, 0px 5px 30px #0e4a4814 !important;
		box-shadow: 0 0 0px 1000px #fff  inset, 0px 5px 30px #0e4a4814 !important; 
		transition: background-color 5000s ease-in-out 0s !important;
}
input:valid{background-color: #fff !important;}
/*white btn*/
.white-btn {font-size: 18px;line-height: 23px;color: #725FEE;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 26px #3524A524;border-radius: 4px;padding: 10px 24px 10px 60px;height: 55px;border:none;position: relative;overflow: hidden;margin: 5px 0px;}
.white-btn .btn-text{padding-left: 0px;}
.white-btn .purple-person {display: block;width: 60px;height: 60px;overflow: hidden;position: absolute;left: 0;top: 50%;transform: translateY(-50%);}
.white-btn .white-person{display: none;}
.white-btn:hover .purple-person{display: none;}
.white-btn:hover .white-person{display: block;width: 60px;height: 60px;overflow: hidden;position: absolute;left: 0;top: 50%;transform: translateY(-50%);}
.white-btn:before,
.white-btn:after {content: "";position: absolute;display: block;box-sizing: border-box;top: 0;left: 0;}
.white-btn:after {top: 50%;left: 50%;width: 0;height: 0;border-radius: 50%;background: transparent linear-gradient(86deg, #8758E5 0%, #6F60EE 100%) 0% 0% no-repeat padding-box;color: #000000 !important;transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);}
.white-btn:hover:after {top: 0;left: 0;width: 100%;height: 100%;border-radius: 2px;color: #000000;}
.white-btn span {position: relative;z-index: 2;color: #725FEE;}
.white-btn:hover span {color: #fff;}
/*purple-btn*/
.purple-btn {font-size: 18px;line-height: 23px;background: transparent linear-gradient(86deg, #8758E5 0%, #6F60EE 100%) 0% 0% no-repeat padding-box;color: #fff;box-shadow: 0px 3px 26px #3524A524;border-radius: 4px;padding: 10px 24px 10px 50px;height: 55px;border: none;position: relative;overflow: hidden;margin: 5px 0px;}
.purple-btn .btn-text{padding-left: 16px;}
.purple-btn:hover{color: #725FEE;}
.purple-btn .white-wallet{display: block;width: 120px;height: 120px;overflow: hidden; position: absolute;left: -18px;top: 50%;transform: translateY(-50%);}
.purple-btn .purple-wallet{display: none;}
.purple-btn:hover .white-wallet{display: none;}
.purple-btn:hover .purple-wallet{display: block;width: 120px;height: 120px;overflow: hidden;position: absolute;left: -18px;top: 50%;transform: translateY(-50%);}
.purple-btn:before,
.purple-btn:after {content: "";position: absolute;display: block;box-sizing: border-box;top: 0;left: 0;}
.purple-btn:after {top: 50%;left: 50%;width: 0;height: 0;border-radius: 50%;background: #FFFFFF;color: #000000 !important;transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);}
.purple-btn:hover:after {top: 0;left: 0;width: 100%;height: 100%;border-radius: 2px;color: #000000;}
.purple-btn span {position: relative;z-index: 2;color: #fff;}
.purple-btn:hover span {color: inherit;}
/*transparent-btn*/
.transparent-btn{font-size: 18px;line-height: 23px;background: #fff; color: #725FEE;box-shadow: 0px 3px 26px #3524A524;border-radius: 4px;padding: 10px 24px;border: 1px solid #725FEE;height: 55px;position: relative;overflow: hidden;margin: 5px 0px;}
.transparent-btn:before,
.transparent-btn:after {content: "";position: absolute;display: block;box-sizing: border-box;top: 0;left: 0;}
.transparent-btn:after {top: 50%;left: 50%;width: 0;height: 0;border-radius: 50%;background: linear-gradient(86deg, #8758E5 0%, #6F60EE 100%);color: #000000 !important;transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);}
.transparent-btn:hover:after {top: 0;left: 0;width: 100%;height: 100%;border-radius: 2px;color: #000000;}
.transparent-btn span {position: relative;z-index: 2;color: inherit;}
.transparent-btn:hover span {color: inherit;}
.transparent-btn:active, .transparent-btn:focus, .transparent-btn:first-child:active {color: #fff;background: linear-gradient(86deg, #8758E5 0%, #6F60EE 100%);border-color: #725FEE;}
.transparent-btn:hover{color: #fff;background: #fff;border-color: #725FEE;}
/*purple-border-btn*/
.purple-border-btn{font-size: 18px;line-height: 23px; background: linear-gradient(86deg, #8758E5 0%, #6F60EE 100%); color: #fff;box-shadow: 0px 3px 26px #3524A524;border-radius: 4px;padding: 10px 24px;border: 1px solid #725FEE;height: 55px;min-width: 141px;position: relative;overflow: hidden; margin: 5px 0px;}
.purple-border-btn:before,
.purple-border-btn:after {content: "";position: absolute;display: block;box-sizing: border-box;top: 0;left: 0;}
.purple-border-btn:after {top: 50%;left: 50%;width: 0;height: 0;border-radius: 50%;background: #fff;color: #000000 !important;transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);}
.purple-border-btn:hover:after {top: 0;left: 0;width: 100%;height: 100%;border-radius: 2px;color: #000000;}
.purple-border-btn span {position: relative;z-index: 2;color: #fff;}
.purple-border-btn:hover span {color: #725FEE;}
/*disabled*/
.purple-border-btn[disabled]{ background: linear-gradient(86deg, #8758E5 0%, #6F60EE 100%); color: #fff;border: 1px solid #725FEE;cursor: not-allowed;opacity: 0.7;}
.purple-border-btn[disabled]:hover:before, .purple-border-btn[disabled]:hover:after{display: none;}
.purple-border-btn[disabled]:hover span {color: #fff;}
/*home page code*/
.how-it-works .hiw-head .small-head, .choose-staking .staking-head .small-head, .bonus-policy .bonus-head .small-head, .who-we-are .small-head, .Our-mission .small-head, .choose-us .small-head, .get-touch .small-head, .packages-section .packages-head .small-head, .main-referral .small-head, .referral-policy .referral-head .small-head{color: #725FEE;font-size: 20px;line-height: 25px;display: block;}
.how-it-works .hiw-head h2, .choose-staking .staking-head h2, .bonus-policy .bonus-head h2, .who-we-are h2, .Our-mission h2, .choose-us h2, .get-touch h2, .packages-section .packages-head h2, .main-referral h2, .referral-policy .referral-head h2, .faqs h2{font-size: 56px;line-height: 71px;font-weight: bold;}
.how-it-works .hiw-head p, .choose-staking .staking-head p, .bonus-policy .bonus-para p, .referral-policy .referral-para p, .faqs p, .privacy-policy p, .terms-conditions p{font-size: 18px;line-height: 30px;opacity: 0.4;}
.who-we-are .who-para p, .Our-mission .mission-para p, .choose-us .choose-head p, .main-banner p, .get-touch .contact-para p, .packages-section p, .main-referral p {font-size: 22px;line-height: 32px;opacity: 0.5;}
/**/
.complete-content {height: 100vh;overflow-y: auto;}
.right-content {padding-left: 280px;padding-bottom: 60px;min-height: 100vh;}
.menu-icon {font-size: 40px;color: #fff;cursor: pointer;padding: 32px;display: none;position: absolute;z-index: 3;}
/*table*/
.tabs-body-table {min-width: 100%;}
.tabs-body-table .table{margin-bottom: 0px;}
.tabs-body-table .table thead {background: #6F60EE 0% 0% no-repeat padding-box !important;border-radius: 4px 4px 0px 0px;}
.tabs-body-table .table thead th {font: normal normal 600 20px/26px Outfit;letter-spacing: 0px;color: #FFFFFF;}
.tabs-body-table .table thead tr {border-color: transparent;padding: 11px;}
.tabs-body-table .table thead {border: none;}
.tabs-body-table table tbody tr:last-child,
.tabs-body-table table tbody tr:first-child {border: none;}
.tabs-body-table .table thead th {min-width: 164px;}
.tabs-body-table .table tbody tr td {min-width: 110px;font: normal normal normal 14px/18px Outfit;letter-spacing: 0px;color: #FFFFFF;padding: 11px;}
.tabs-body-table .table-bordered>:not(caption)>*>* {border-width: 0;}
.tabs-body-table .table tbody {background-color: #887cf3;}
.tab-content>.active {background-color: transparent;}
/**/
.modal-backdrop.show { background-color: rgba(0, 0, 0, 0.3);}
/*table*/
.modal .modal-body .input-section {margin: 0 0 30px;}

.modal .modal-body .input-section .detail-1 {max-width: 45%; word-break: break-word;}

.modal .modal-body .input-section input {background: #fff 0 0 no-repeat padding-box;border: none;border-radius: 6px;box-shadow: 0 5px 30px #0e4a4814;font-size: 18px;height: 65px;line-height: 23px;padding: 21px 20px;width: 100%;    grid-area: 1 / 1 / auto / auto !important;}
.modal .modal-body .input-section textarea {background: #fff 0 0 no-repeat padding-box;border: none;border-radius: 6px;box-shadow: 0 5px 30px #0e4a4814;font-size: 18px;height: 172px;line-height: 23px;padding: 21px 20px;width: 100%;}
.modal .modal-body .input-section .form-img {width: 190px;height: 190px;border-radius: 50%;margin: 0 auto 30px;}
.modal .modal-body .input-section .form-input {position: relative;width: 150px;height: 45px;margin: 0 auto 5px;display: flex;border-radius: 4px;overflow: hidden;}
.modal .modal-body .input-section .form-input input {width: 100%;height: 100%;display: block;font-size: 0;opacity: 0;position: relative;z-index: 1;cursor: pointer;}
.modal .modal-body .input-section .form-input .input-file-btn {width: 100%;height: 100%;position: absolute;left: 0;top: 0;background: #887cf3;color: #fff;font-size: 18px;line-height: 23px;}
.modal .btn-close:focus {outline: 0;box-shadow: none;}
.modal .modal-body .input-section select.form-control {min-height: 65px;font-size: 18px;line-height: 23px;padding: 21px 20px;}
.modal .modal-body .input-section select.form-control:focus {outline: rgba(111, 96, 238, 0.5) solid 2px !important; box-shadow: none;}
/*react select*/
.select__control{border-radius: 6px !important; border: none !important;box-shadow: 0px 5px 30px #0e4a4814 !important;}
.select__control--is-focused.select__control--menu-is-open{ outline: rgba(111, 96, 238, 0.5) solid 2px !important;border-width: 2px;border-style: solid;box-shadow: none !important;}
.select__control--is-focused.select__control--menu-is-open:hover{outline: rgba(111, 96, 238, 0.5) solid 2px !important;}
.select__control--is-focused{outline: rgba(111, 96, 238, 0.5) solid 2px !important;box-shadow: none !important;}
.select__control input{min-width: 0px !important;background-color: transparent !important;}
.select__control--is-focused.select__control--menu-is-open input:focus{outline: none !important;}
.select__control--is-focused .select__input{outline: none !important;}
.select__single-value{margin-left: 5px !important;}
.select__control--is-focused.select__control--menu-is-open input::placeholder{padding-left: 10px;}
.select__option--is-selected{background-color: #6F60EE !important;}
.select__option--is-focused{background-color: #988df5 !important;color: #fff !important;}
.select__input-container {height: 65px;}
/* .css-1s2u09g-control{border-color: transparent !important;} */
.select__value-container {padding: 0 12px !important;}
/**/
.swal2-popup.swal2-modal.swal2-icon-success.swal2-show, .swal2-show {background:  transparent linear-gradient(241deg, #FDE6FF 20%, #E3F8FF 100%) 0% 0% no-repeat padding-box; color: #000;}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left], .swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right, .swal2-icon.swal2-success .swal2-success-fix{background:  transparent linear-gradient(241deg, #FDE6FF 20%, #E3F8FF 100%) 0% 0% no-repeat padding-box !important; }
.swal2-icon.swal2-success .swal2-success-ring {border: 0.25em solid #887cf3 !important;}
.swal2-icon.swal2-success [class^=swal2-success-line] {background-color: #887cf3 !important;}
.swal2-close:hover {color: #6F60EE !important;}
button.swal2-confirm {background: transparent linear-gradient(86deg, #8758E5 0%, #6F60EE 100%) 0% 0% no-repeat padding-box !important;border: 1px solid #6F60EE !important;color: #fff !important;min-width: 100px;}
button.swal2-confirm:hover{background: #fff !important;border: 1px solid #6F60EE !important;color: #6F60EE  !important;}
button.swal2-confirm:focus{box-shadow: none !important;outline: 0 !important;}
[class^=swal2] {-webkit-tap-highlight-color: transparent !important;}
.swal2-icon.swal2-warning {border-color: #6F60EE !important;color: #6F60EE !important;}
/*cms page*/
.cms-page {margin: 150px auto 200px;width: 80%;padding: 50px;background: transparent linear-gradient(180deg, #FDE6FF 0%, #E3F8FF 100%) 0% 0% no-repeat padding-box;box-shadow: 0px 5px 50px #0e4a481a;border: 4px solid #FFFFFF;border-radius: 9px;}
.cms-page h2 {font-size: 54px;line-height: 71px;font-weight: bold;}
.cms-page h3 {font-size: 42px;line-height: 52px;font-weight: bold;}
.cms-page h4 {font-size: 30px;line-height: 38px;font-weight: bold;}
.cms-page p {font-size: 22px;line-height: 32px;}
.cms-page p a {text-decoration: none;color: #6F60EE;cursor: pointer;}
.cms-page p::first-letter {text-transform: capitalize;}
.cms-page ul li, .cms-page ol li {font-size: 18px;line-height: 28px;}
.cms-page figure {width: 300px;height: 300px;border-radius: 10px;overflow: hidden;}
.cms-page figure img{width: 100%;height: 100%;object-fit: contain;}
.cms-page .table {background-color: #fff;border-radius: 10px;box-shadow: 0px 5px 50px #0203070d;padding: 30px;width: auto !important;height: auto !important;max-height: 800px;overflow: auto;}
.cms-page .table tr{border: none !important;}
.cms-page .table th {font-size: 22px;line-height: 35px;font-weight: bold;padding: 20px;}
.cms-page .table td {font-size: 22px;line-height: 35px;font-weight: 400;padding: 20px;}
/* responsive */


.red-border-btn{font-size: 18px;line-height: 23px; background: #401c86; color: #fff;box-shadow: 0px 3px 26px #3524A524;border-radius: 4px;padding: 10px 24px;border: 1px solid #401c86;height: 55px;min-width: 141px;position: relative;overflow: hidden; margin: 5px 0px;}
@media only screen and (max-width:1919px){

}
@media only screen and (max-width:1799px){
  .how-it-works .hiw-head h2, .choose-staking .staking-head h2, .bonus-policy .bonus-head h2, .who-we-are h2, .Our-mission h2, .choose-us h2, .get-touch h2, .packages-section .packages-head h2, .main-referral h2, .referral-policy .referral-head h2, .faqs h2 {font-size: 50px;line-height: 65px;}
}
@media only screen and (max-width:1599px){
  .transparent-btn, .purple-border-btn, .purple-btn, .white-btn {height: 55px;}
  .how-it-works .hiw-head h2, .choose-staking .staking-head h2, .bonus-policy .bonus-head h2, .who-we-are h2, .Our-mission h2, .choose-us h2, .get-touch h2, .packages-section .packages-head h2, .main-referral h2, .referral-policy .referral-head h2, .faqs h2 {font-size: 45px;line-height: 60px;}
  /*cms page*/
  .cms-page {width: 90%;}
  .cms-page h2 {font-size: 45px;line-height: 62px;}
  .cms-page h3 {font-size: 40px;line-height: 52px;}
  .cms-page h4 {font-size: 35px;line-height: 42px;}
  .cms-page p {font-size: 20px;line-height: 32px;}
  .cms-page .table td, .cms-page .table th {font-size: 18px;padding: 16px;}
}
@media only screen and (max-width:1399px){
  .transparent-btn, .purple-border-btn, .purple-btn, .white-btn {font-size: 16px;line-height: 21px;padding: 10px 24px;height: 50px;}
  .how-it-works .hiw-head h2, .choose-staking .staking-head h2, .bonus-policy .bonus-head h2, .who-we-are h2, .Our-mission h2, .choose-us h2, .get-touch h2, .packages-section .packages-head h2, .main-referral h2, .referral-policy .referral-head h2, .faqs h2 {font-size: 40px;line-height: 55px;}
  .who-we-are .who-para p, .Our-mission .mission-para p, .choose-us .choose-head p, .main-banner p, .get-touch .contact-para p, .packages-section p, .main-referral p {font-size: 18px;line-height: 26px;}
  .white-btn .purple-person, .white-btn:hover .white-person {width: 50px;height: 50px;}
  .white-btn {padding: 10px 24px 10px 50px;}
  .purple-btn {padding: 10px 24px 10px 40px;}
  .purple-btn .white-wallet, .purple-btn:hover .purple-wallet{left: -26px;}
  .purple-border-btn {min-width: 125px;}
}
@media only screen and (max-width:1199px){
  .white-btn .btn-text, #header .purple-btn .btn-text {display: none;}
  .how-it-works .hiw-head h2, .choose-staking .staking-head h2, .bonus-policy .bonus-head h2, .who-we-are h2, .Our-mission h2, .choose-us h2, .get-touch h2, .packages-section .packages-head h2, .main-referral h2, .referral-policy .referral-head h2, .faqs h2 {font-size: 30px;line-height: 45px;}
  .how-it-works .hiw-head .small-head, .choose-staking .staking-head .small-head, .bonus-policy .bonus-head .small-head, .who-we-are .small-head, .Our-mission .small-head, .choose-head .small-head, .get-touch .small-head, .packages-section .packages-head .small-head, .main-referral .small-head, .referral-policy .referral-head .small-head {font-size: 17px;line-height: 22px;}
  .purple-btn {padding: 10px 22px 10px 38px;}
  .purple-btn .white-wallet, .purple-btn:hover .purple-wallet {left: -34px;}
  .white-btn {padding: 10px 24px 10px 36px;}
  .white-btn .purple-person, .white-btn:hover .white-person {width: 60px;height: 60px;}
  .right-content {padding-left: 240px;}
  /*cms page*/
  .cms-page {width: 100%;}
  .cms-page h2 {font-size: 40px;line-height: 62px;}
  .cms-page h3 {font-size: 35px;line-height: 52px;}
  .cms-page h4 {font-size: 30px;line-height: 42px;}
  .cms-page p {font-size: 18px;line-height: 32px;}
  .cms-page .table td, .cms-page .table th {font-size: 16px;padding: 16px;}
  .cms-page ul li, .cms-page ol li {font-size: 16px;line-height: 28px;}
  .cms-page .table {padding: 15px;}
}
@media only screen and (max-width:991px){
  .right-content {padding-left: 0px;}
  .menu-icon {font-size: 25px;display: block;color: #401c86;z-index: 9999;right :15px;right: 35px;padding: 0;top: 38px;}
}
@media only screen and (max-width:767px){
  .modal {z-index: 99999;}
   /*cms page*/
   .cms-page {padding: 25px; margin: 50px auto 150px;}
   .cms-page h2 {font-size: 35px;line-height: 62px;}
   .cms-page h3 {font-size: 30px;line-height: 52px;}
   .cms-page h4 {font-size: 25px;line-height: 42px;}
   .cms-page p {font-size: 16px;line-height: 32px;}
   .cms-page .table td, .cms-page .table th {font-size: 14px;padding: 16px;}
   .cms-page ul li, .cms-page ol li {font-size: 14px;line-height: 28px;}
}
@media only screen and (max-width:575px){

}
@media only screen and (max-width:479px){
  .purple-border-btn {min-width: auto;}
  .transparent-btn, .purple-border-btn {padding: 10px 20px;}
  .menu-icon {right: 30px;}
  .modal {padding-left: 0px !important;}
  /**/
  .modal .modal-body .input-section input, .modal .modal-body .input-section textarea {font-size: 15px;line-height: 20px;padding: 21px 15px;}
  input {height: 45px;font-size: 15px !important;}
  div#react-select-7-placeholder{font-size: 15px !important;}
  .right-content .purple-btn .btn-text{display: none;}
  /*cms page*/
  .cms-page h2 {font-size: 30px;line-height: 40px;}
  .cms-page h3 {font-size: 25px;line-height: 35px;}
  .cms-page h4 {font-size: 20px;line-height: 30px;}
  .cms-page p {font-size: 15px;line-height: 25px;}
  .cms-page .table td, .cms-page .table th {font-size: 14px;padding: 16px;}
  .cms-page ul li, .cms-page ol li {font-size: 14px;line-height: 24px;}
  .cms-page figure {width: 100%;height: 250px;}
}
@media only screen and (max-width:319px){
  .how-it-works .hiw-head h2, .choose-staking .staking-head h2, .bonus-policy .bonus-head h2, .who-we-are h2, .Our-mission h2, .choose-us h2, .get-touch h2, .packages-section .packages-head h2, .main-referral h2, .referral-policy .referral-head h2, .faqs h2 {font-size: 25px;line-height: 36px;}
  .how-it-works .hiw-head p, .choose-staking .staking-head p, .bonus-policy .bonus-para p, .referral-policy .referral-para p, .faqs p, .privacy-policy p, .terms-conditions p {font-size: 16px;}
  .transparent-btn, .purple-border-btn {padding: 10px 16px;}
  .who-we-are .who-para p, .Our-mission .mission-para p, .choose-us .choose-head p, .main-banner p, .get-touch .contact-para p, .packages-section p, .main-referral p{font-size: 16px;}
  .menu-icon {right: 20px;}
  /*cms*/
  .cms-page figure {height: 200px;}
}
@media print {
  header, footer, aside, form, .main-banner {display: none;}
  .container{max-width: 100% !important;}
  .cms-page {margin: 0px !important;width: 100% !important;padding: 30px !important;background: none !important;box-shadow: none !important;border: none !important;border-radius: 0px !important}
}

