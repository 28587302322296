.who-we-are {padding: 85px 0;}
.who-we-are .small-head{padding-left: 75px;position: relative;}
.who-we-are .small-head::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);left: 0%;height: 2px;background-color: #725FEE;width: 60px;}
.who-we-are .who-head{margin: 0 0 42px;}
.who-we-are .who-para{max-width: 800px;}
.who-we-are .who-para p{margin: 0 0 42px;}
.who-we-are .who-para p:last-of-type{margin: 0 0 100px;}
.who-we-are .who-buttons .transparent-btn {margin-left: 30px;min-width: 173px;display: flex;justify-content: center;align-items: center;}
.who-we-are .who-buttons .purple-border-btn {min-width: 165px;}
/* responsive */
@media only screen and (max-width:1399px){
    .who-we-are .who-buttons .transparent-btn {margin-left: 20px;}
}
@media only screen and (max-width:991px){
    .who-we-are {padding: 50px 0 85px;}
    .who-we-are .who-img {width: 75%;margin: 0 auto;}
}
@media only screen and (max-width:575px){
    .who-we-are .who-buttons .transparent-btn {min-width: 153px;}
    .who-we-are .who-buttons .purple-border-btn {min-width: 145px;}
}
@media only screen and (max-width:479px){
    .who-we-are .who-img {width: 100%;}
    .who-we-are .who-buttons .transparent-btn {min-width: 133px;}
    .who-we-are .who-buttons .purple-border-btn {min-width: 125px;}
}
@media only screen and (max-width:319px){
    .who-we-are .who-buttons .transparent-btn {min-width: 120px;}
    .who-we-are .who-buttons .purple-border-btn {min-width: 120px;}
}