.withdrawal-section{min-height: calc(100vh - 60px);background: transparent linear-gradient(241deg, #FDE6FF 20%, #E3F8FF 100%) 0% 0% no-repeat padding-box;padding: 150px 39px 50px;min-width: calc(100% - 280px);}
.withdrawal-section .withdrawal-details h2 {font-weight: 700;font-size: 36px;line-height: 40px;letter-spacing: 2.88px;margin-bottom: 50px;}
.withdrawal-section .create-button{margin: 0 0 50px;}
.withdrawal-section .withdrawal-table{background-color: #fff;border-radius: 10px;box-shadow: 0px 5px 50px #0203070d;padding: 30px;}
.withdrawal-section .withdrawal-table .table-img {width: 70px;height: 70px;border-radius: 10px;overflow: hidden;}
.withdrawal-section .withdrawal-table .table-img img{width: 100%;height: 100%;object-fit: cover;}
.withdrawal-section .withdrawal-table .main-row{min-height: 70px;}
.withdrawal-section .withdrawal-table .main-row .view-button, .withdrawal-section .withdrawal-table .main-row .delete-button{display: flex;justify-content: center;align-items: center;width: 45px;height: 45px;}
.withdrawal-section .withdrawal-table .main-row .delete-button{background-color: red;border-color: red;}
.withdrawal-section .withdrawal-table .table{table-layout: fixed;}
.withdrawal-section .withdrawal-table .td-start{width: 100px;}
.withdrawal-section .withdrawal-table .td-image{width: 250px;}
.withdrawal-section .withdrawal-table .td-title{width: 250px;}
.withdrawal-section .withdrawal-table .td-amount{width: 250px;}
.withdrawal-section .withdrawal-table .td-address{width: 368px;}
.withdrawal-section .withdrawal-table .td-status{width: 250px;}
.withdrawal-section .withdrawal-table .td-date{width: 250px;}
.withdrawal-section .withdrawal-table .status-span {padding: 7px 10px;border-radius: 4px;min-width: 111px;text-align: center;}
.withdrawal-section .withdrawal-table .status-span.approved {background-color: green;}
.withdrawal-section .withdrawal-table .status-span.not-approved {background-color: red;}
.withdrawal-section .withdrawal-table .status-span.pending {background-color: grey;}
/**/
.withdrawal-section .withdrawal-table .td-address .withdrawal-address{max-width: 95%;overflow: hidden;text-overflow: ellipsis;}
/*total-balance*/
.withdrawal-section .total-balance {background: transparent linear-gradient(180deg, #FDE6FF 0%, #E3F8FF 100%) 0% 0% no-repeat padding-box;box-shadow: 0px 5px 50px #0e4a481a;border: 4px solid #FFFFFF;border-radius: 9px;padding: 30px 20px;margin: 0 0 30px;display: inline-block; max-width: 100%;}
.withdrawal-section .total-balance p{margin: 0;display: inline-flex;align-items: center; font-size: 20px; max-width: 100%;}
.withdrawal-section .total-balance p strong{width: 134px;margin-right: 10px;}
.withdrawal-section .total-balance p .balance {max-width: calc(100% - 144px);text-overflow: ellipsis; overflow: hidden;white-space: nowrap;display: block;}
/*responsive*/
@media (max-width: 1900px) {
    
}
@media (max-width: 1799px){

}
@media (max-width: 1599px) {
    .withdrawal-section .withdrawal-details h2 {font-size: 33px;line-height: 36px;}
}
@media (max-width: 1399px) {
    
}
@media (max-width: 1199px) {
    
}
@media (max-width: 991px){
    .withdrawal-section{padding: 150px 12px 50px;}
}
@media (max-width: 767px){
    .withdrawal-section .withdrawal-details h2 {font-size: 28px;line-height: 30px;}
}
@media (max-width: 575px) {
    
}
@media (max-width: 479px){
    .withdrawal-section .withdrawal-details h2 {font-size: 28px;line-height: 30px;}
    .withdrawal-section{padding: 170px 0px 50px;}
    /**/
    .withdrawal-section .create-button {margin: 0 0 50px;display: flex;flex-direction: column;justify-content: center;align-items: center;}
    .withdrawal-section .purple-border-btn {min-width: 186px;margin: 5px 0px !important;display: block;}
    /**/
    .withdrawal-section .total-balance p {flex-direction: column;}
    .withdrawal-section .total-balance p .balance {max-width: 100%;}
}
@media (max-width: 360px) {

}
@media (max-width: 319px) {
    .withdrawal-section .withdrawal-details h2 {font-size: 26px;line-height: 28px;}
}