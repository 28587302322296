#header{padding: 40px 0px;position: absolute; width: 100%;top: 0;left: 0;}
#header .logo{width: 173px;}
#header .nav-menu{width: calc(100% - 173px);}
#header .navbar-collapse {margin-left: 91px;justify-content: space-between;}
#header .navbar-collapse ul li{margin: 0px 23px;}
#header .navbar-collapse ul li a{font-size: 18px;line-height: 23px;padding: 0;position: relative;font-weight: normal;color: #09091A;}
#header .navbar-collapse ul li a:hover{color: #6F60EE;}
#header .navbar-collapse ul li a::after{content: "";position: absolute;bottom: -2px;left: 0;height: 2px;width: 0%;background-color: #6F60EE;transition: all ease-in-out 0.3s;}
#header .navbar-collapse ul li a:hover::after{width: 100%;}
#header .purple-btn{margin-left: 19px;display: flex;align-items: center;justify-content: center;}
#header  .white-btn {display: flex;align-items: center;justify-content: center;}
#header .navbar-collapse ul li.active a{color: #6F60EE;}
#header .navbar-collapse ul li.active a::after{content: "";position: absolute;bottom: -2px;left: 0;height: 2px;width: 100%;background-color: #6F60EE;}
#header .header-collapse-buttons{display: none;}
#header .header-main-buttons{display: flex;}
/* responsive */
@media only screen and (max-width:1399px){
    #header .navbar-collapse {margin-left: 50px;}
    #header .navbar-collapse ul li a {font-size: 16px;line-height: 21px;}
    #header .purple-btn, #header .white-btn {font-size: 16px;}
}
@media only screen and (max-width:1199px){
    #header .logo {width: 140px;}
    #header .nav-menu{width: calc(100% - 140px);}
}
@media only screen and (max-width:991px){
    #header .navbar-toggler {position: absolute;right: 0;top: 50%;transform: translateY(-50%);padding: 0;border: 0;}
    #header .nav-menu {width: calc(100% - 140px);justify-content: flex-end !important;padding-right: 40px;}
    #header .navbar-toggler .bars-icon{font-size: 24px; color: #6F60EE;}
    #header .navbar-toggler:focus{box-shadow: none; outline: none;}
    #header .navbar-collapse {margin-left: 0px;position: absolute;left: 0;right: 0;background: #fff;top: 100%;padding: 20px;box-shadow: 0px 3px 26px #3524A524;z-index: 99;}
    #header .navbar-collapse ul li {margin: 10px 23px;}
    #header .navbar-collapse ul li.active a::after, #header .navbar-collapse ul li a::after{display: none;}
}    
@media only screen and (max-width:479px){
    #header .header-collapse-buttons{display: flex;justify-content: center;}
    #header .header-main-buttons{display: none;}
}