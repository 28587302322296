.dashboard{min-height: calc(100vh - 60px);background: transparent linear-gradient(241deg, #FDE6FF 20%, #E3F8FF 100%) 0% 0% no-repeat padding-box;padding: 150px 39px 50px;min-width: calc(100% - 280px);}
.dashboard .dashboard-details h2 {font-weight: 700;font-size: 36px;line-height: 40px;letter-spacing: 2.88px;margin-bottom: 50px;}
.dashboard .dashboard-boxes .card-stats{background-color: #fff;border: none;border-radius: 10px;margin: 0 0 30px;transition: all ease-in-out 0.3s;}
.dashboard .dashboard-boxes .card-stats:hover{box-shadow: 0px 0px 10px #725FEE;}
.dashboard .dashboard-boxes .card-stats .card-body{color: #09091A;padding: 0;display: flex;justify-content: center;align-items: center;}
.dashboard .dashboard-boxes .card-stats .card-body .inner-stats{color: #09091A;width: 100%;padding: 20px;}
.dashboard .dashboard-boxes .card-stats .card-category {font-size: 20px;line-height: 30px;}
.dashboard .dashboard-boxes .card-stats h4 {font-size: 50px;line-height: 63px;min-height: 63px;}
.dashboard .dashboard-boxes .card-stats .numbers {width: calc(100% - 95px);}
.dashboard .dashboard-boxes .card-stats .icon-big {width: 85px;height: 85px;border-radius: 50%;background-color: #725FEE;margin-left: 10px;padding: 20px;}
.dashboard .dashboard-boxes .card-stats .icon-big .box-icon{width: 100%;height: 100%;color: #fff;font-weight: bold;}
/*responsive*/
@media (max-width: 1900px) {
    
}
@media (max-width: 1799px){
    .dashboard .dashboard-boxes .card-stats h4 {font-size: 40px;line-height: 50px;min-height: 50px;}
    .dashboard .dashboard-boxes .card-stats .card-body .inner-stats {min-height: 174px;}
}
@media (max-width: 1599px) {
    .dashboard .dashboard-details h2 {font-size: 33px;line-height: 36px;}
    .dashboard .dashboard-boxes .card-stats h4 {font-size: 30px;line-height: 38px;min-height: 38px;}
    .dashboard .dashboard-boxes .card-stats .card-category {font-size: 18px;line-height: 26px;}
}
@media (max-width: 1399px) {
    
}
@media (max-width: 1199px) {
    /* .dashboard .dashboard-boxes .card-stats h4 {font-size: 30px;line-height: 40px;} */
    .dashboard .dashboard-boxes .card-stats .icon-big {width: 65px;height: 65px;padding: 15px;}
    .dashboard .dashboard-boxes .card-stats .numbers {width: calc(100% - 75px);}
}
@media (max-width: 991px){
    .dashboard{padding: 150px 12px 50px;}
}
@media (max-width: 767px){
    .dashboard .dashboard-details h2 {font-size: 28px;line-height: 30px;}
    .dashboard .dashboard-boxes .card-stats .card-body .inner-stats {
        min-height: auto;
    }
}
@media (max-width: 575px) {
    .dashboard .dashboard-boxes .card-stats .icon-big {margin-left: 0px;margin-right: 15px;}
}
@media (max-width: 479px){
    .dashboard .dashboard-details h2 {font-size: 28px;line-height: 30px;}
    .dashboard{padding: 170px 0px 50px;}
    .dashboard .dashboard-boxes .card-stats .card-body .inner-stats {flex-direction: column;text-align: center;padding: 20px 12px;}
    .dashboard .dashboard-boxes .card-stats .numbers {width: 100%;}
    .dashboard .dashboard-boxes .card-stats .icon-big {margin-left: 0px;margin-right: 0px;margin-bottom: 15px;}
    .dashboard .dashboard-boxes .card-stats .card-category {font-size: 16px;line-height: 20px;}
    /**/
    .dashboard .dashboard-boxes .card-stats h4 {font-size: 24px;line-height: 32px;min-height: 32px;}
}
@media (max-width: 360px) {

}
@media (max-width: 319px) {
    .dashboard .dashboard-details h2 {font-size: 26px;line-height: 28px;}
}