.get-touch{padding: 104px 0 30px;}
.get-touch {padding: 85px 0;}
.get-touch .small-head{padding-left: 75px;position: relative;}
.get-touch .small-head::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);left: 0%;height: 2px;background-color: #725FEE;width: 60px;}
.get-touch .contact-head{margin: 0 0 19px;}
.get-touch .contact-para p{margin: 0 0 43px;}
.get-touch h2{max-width: 507px;}
.get-touch .contact-data .input-section{margin: 0 0 30px;}
.get-touch .contact-data .input-section input {background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 5px 30px #0E4A4814;border-radius: 6px;font-size: 18px;line-height: 23px;border: none;padding: 21px 20px;width: 100%;height: 65px;}
.get-touch .contact-data .input-section input::placeholder{color: #605F5F;}
.get-touch .contact-data .input-section textarea{width: 100%;height: 172px;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 5px 30px #0E4A4814;border-radius: 6px;font-size: 18px;line-height: 23px;border: none;padding: 21px 20px;}
.get-touch .contact-data .input-section textarea:focus-visible, .get-touch .contact-data .input-section input:focus-visible{outline: none;}
/*contact box*/
.get-touch .contact-box{background: transparent linear-gradient(180deg, #FDE6FF 0%, #E3F8FF 100%) 0% 0% no-repeat padding-box;box-shadow: 0px 5px 50px #0E4A481A;border: 4px solid #FFFFFF;border-radius: 9px;width: 100%;max-width: 723px;margin: 0 auto;padding: 73px 68px;}
.get-touch .contact-box .contact-data{width: 50%;margin: 0 0 44px;}
.get-touch .contact-box .contact-data:nth-child(3){margin: 0;}
.get-touch .contact-box .contact-data:nth-child(4){margin: 0;}
.get-touch .contact-box .contact-data h2{font-size: 20px;line-height: 26px;margin: 0 0 23px;font-weight: bold;}
.get-touch .contact-box .contact-data ul{padding: 0; list-style: none;}
.get-touch .contact-box .contact-data li{font-size: 18px;line-height: 30px;margin: 0 0 16px;}
.get-touch .contact-box .working-hours li:last-of-type{margin: 0;}
.get-touch .contact-box .contact-data a{font-size: 18px;line-height: 30px;color: #09091A;position: relative;}
.get-touch .contact-box .contact-data a:hover{color: #725FEE;}
.get-touch .contact-box .contact-data a::after{content: "";position: absolute;height: 1px;background-color: #09091A;left: 0;bottom: 0;width: 100%;}
.get-touch .contact-box .contact-data .contact-list{padding: 0;list-style: none;margin: 0;}
.get-touch .contact-box .contact-data .contact-list .conatct-no::after{display: none;}
.get-touch .contact-box .contact-data .social-list{padding: 0;list-style: none;margin: 0;}
.get-touch .contact-box .contact-data .social-list li{margin: 0 19px;}
.get-touch .contact-box .contact-data .social-list li:first-of-type{margin-left: 0;}
.get-touch .contact-box .contact-data .social-list li:last-of-type{margin-right: 0;}
.get-touch .contact-box .contact-data .social-list li a::after{display: none;}
/* .get-touch .contact-box .contact-data .social-list li a:hover{color: #725FEE;} */
/**/
.get-touch .contact-box .contact-data .social-list li .facebook:hover{color: #1877f2;}
.get-touch .contact-box .contact-data .social-list li .linkedin:hover{color: #0a66c2;}
.get-touch .contact-box .contact-data .social-list li .twitter:hover{color: #1da1f2;}
.get-touch .contact-box .contact-data .social-list li .pininterest:hover{color: #bd081c;}
.get-touch .contact-box .contact-data .social-list li .youtube:hover{color: #ff0000;}
/* responsive */
@media only screen and (max-width:1399px){
    .get-touch .contact-box {padding: 58px 30px;}
}
@media only screen and (max-width:1199px){
    .get-touch .contact-box .contact-data {width: 96%;margin: 0 2% 30px;}
    .get-touch .contact-box .contact-data:nth-child(3) {margin: 0 2% 30px;}
    .get-touch .contact-box .contact-data:nth-child(4) {margin: 0 2% 0px;}
}
@media only screen and (max-width:991px){
    .get-touch .contact-data .input-section input, .get-touch .contact-data .input-section textarea {font-size: 15px;line-height: 20px;padding: 21px 14px;}
}
@media only screen and (max-width:767px){
    .get-touch .contact-data {margin: 0 0 30px;}
}
@media only screen and (max-width:479px){
    .get-touch .contact-box .contact-data .social-list li {margin: 0 15px;}
    .get-touch .contact-box .contact-data li {font-size: 15px;line-height: 26px;}
    .get-touch .contact-data .input-section input, .get-touch .contact-data .input-section textarea {font-size: 14px;line-height: 18px;}
}
@media only screen and (max-width:319px){
    .get-touch .contact-box .contact-data .social-list li {margin: 0 12px;}
}