.main-referral{padding: 104px 0px;}
.main-referral .referral-head{margin: 0 0 167px;}
.main-referral .referral-head p{max-width: 1290px;margin: 0 auto;}
.main-referral .referral-boxes{box-shadow: 0px 3px 36px #725FEE26;border: 6px solid #FFFFFF;border-radius: 20px;padding: 109px 65px 14px;position: relative;}
.main-referral .referral-boxes .referral-lottie{height: 333px;overflow: hidden;width: 100%;}
.main-referral .blue-bg{background: #E3F8FF 0% 0% no-repeat padding-box;}
.main-referral .pink-bg{background: #FDE6FF 0% 0% no-repeat padding-box;}
.main-referral .blue-pink-bg{background: transparent linear-gradient(241deg, #FDE6FF 0%, #E3F8FF 100%) 0% 0% no-repeat padding-box;}
.main-referral .referral-boxes .label-text {background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 16px #08373A24;border-radius: 7px;padding: 29px 60px;position: absolute;top: -50px;left: 50%;transform: translateX(-50%);min-width: 390px;}
.main-referral .referral-boxes .label-text span{font-size: 30px;line-height: 38px;font-weight: bold;}
/* responsive */
@media only screen and (max-width:1599px){
    .main-referral .referral-boxes .label-text {padding: 24px 12px;min-width: 337px;}
    .main-referral .referral-boxes .label-text span {font-size: 24px;line-height: 30px;}
    .main-referral .referral-boxes {padding: 65px 35px 14px;}
}
@media only screen and (max-width:1399px){
    .main-referral .referral-boxes .label-text {min-width: 260px;}
    .main-referral .referral-boxes .label-text span {font-size: 22px;line-height: 25px;}
    .main-referral .referral-boxes .referral-lottie {height: 280px;}
}
@media only screen and (max-width:1199px){
    .main-referral .referral-boxes {padding: 35px 0px 14px;}
}
@media only screen and (max-width:991px){
    .main-referral {padding: 50px 0px;}
    .main-referral .referral-boxes {padding: 35px 20px 14px;max-width: 522px;margin: 0 auto 90px;}
}
@media only screen and (max-width:767px){
    .referral-policy .input-label span {font-size: 22px;line-height: 25px;}
}
@media only screen and (max-width:479px){
    .referral-policy .input-label span {font-size: 20px;line-height: 22px;}
}
@media only screen and (max-width:319px){
    .main-referral .referral-boxes .label-text {min-width: 210px;}
    .main-referral .referral-boxes .label-text span {font-size: 18px;line-height: 18px;}
    .main-referral .referral-boxes {padding: 35px 0px 14px;}
    .main-referral .referral-boxes .referral-lottie {height: 240px;}
}