.choose-staking{padding: 0 0 100px;}
.choose-staking .staking-head{margin: 0 0 130px;}
.choose-staking .staking-head h2{margin: 0 0 30px;}
.choose-staking .staking-head p{max-width: 1100px;margin: 0 auto;}
.choose-staking .packages-data{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 5px 50px #0203070D;border-radius: 14px;width: 100%;padding: 136px 40px 53px;position: relative;text-align: center; min-height: 595px;margin: 0 0 100px;}
.choose-staking .packages-data h2{font-size: 48px;line-height: 60px;text-transform: uppercase;margin: 0 0 6px !important;font-weight: 900;display: -webkit-box;max-width: 100%;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis; -webkit-line-clamp: 2; max-height: 120px;}
.choose-staking .packages-data .span-font{font-size: 24px;line-height: 30px;display: block;margin: 0 0 30px;}
.choose-staking .packages-data p{font-size: 18px;line-height: 26px;margin: 0 0 20px;opacity: 0.7;display: -webkit-box;max-width: 100%;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis; -webkit-line-clamp: 3; max-height: 80px;}
.choose-staking .packages-data button{width: 199px;}
.choose-staking .packages-data .lottie-cover{width: 150px;height: 150px;border-radius: 50%;margin: 0 auto;box-shadow: 0px 5px 50px #0203070D;position: absolute;left: 50%;transform: translateX(-50%);top: -75px;z-index: 1;overflow: hidden;background-color: #fff;}
.choose-staking .packages-data .lottie-cover svg{width: 200px !important;height: 200px !important;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%) !important;}
.choose-staking .packages-data .red-img{position: absolute;top: -4px;left: 50px;text-align: center;}
.choose-staking .packages-data .red-img .percentage {position: absolute;top: 5px;left: 50%;transform: translateX(-50%);font-size: 20px;font-weight: bold;color: #fff;}
.choose-staking .packages-data .red-img .reward-tag{position: absolute;top: 30px;left: 50%;transform: translateX(-50%);font-size: 16px;color: #fff;}
.choose-staking .packages-area{margin: 0 0 46px;}
.choose-staking .all-packages button{min-width: 226px;}
.choose-staking .packages-data .duration{font: normal normal normal 24px/30px Outfit;margin: 0 0 30px;margin: 0 auto 30px;display: flex;align-items: center;justify-content: center;}
.choose-staking .packages-data .min-limit{max-width: calc(100% - 225px);text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.choose-staking .packages-data .min-limit-1{max-width: calc(100% - 72px);text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.choose-staking .packages-data .months{margin-left: 10px;width: 215px;}
.choose-staking .packages-data .currency{margin-right: 10px;max-width: 195px;white-space: nowrap;}
.choose-staking .packages-data .currency-1 {margin-right: 10px;max-width: 115px;white-space: nowrap;}
.choose-staking .packages-data .amount{max-width: calc(100% - 205px);display: flex;align-items: center;}
.choose-staking .packages-data .amount-1{max-width: calc(100% - 125px);display: flex;align-items: center;}
.choose-staking .packages-data .amount .fixm, .choose-staking .packages-data .amount-1 .fixm{width: 57px; margin-left: 7px;}
.choose-staking .packages-data .amount .limit, .choose-staking .packages-data .amount-1 .limit{max-width: calc(100% - 64px); text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
/**/
.choose-staking .packages-data .lottie-cover .react-loading-skeleton {position: absolute;left: 0;top: 0;right: 0;bottom: 0;}
.choose-staking .packages-data .purple-border-btn .react-loading-skeleton {position: absolute;left: 0;top: 0;right: 0;bottom: 0;}
.choose-staking .packages-data .card-button .react-loading-skeleton{width: 199px;height: 60px;}
.choose-staking .packages-data .percentage .react-loading-skeleton{width: 43px;height: 20px;}
/* .choose-staking .packages-data.show .react-loading-skeleton{display: none;} */
.choose-staking .packages-data a{color: #725FEE;}
.choose-staking .all-packages .transparent-btn {display: inline-flex;align-items: center;}
.choose-staking .token-stake-input{width:199px;height:55px;border:1px solid #000;padding:5px 10px;border-radius:3px;}
/* responsive */
@media only screen and (max-width:1599px){
    .choose-staking .packages-data h2 {font-size: 43px;}
    .choose-staking .packages-data .red-img {left: 30px;}
    /**/
    .choose-staking .packages-data .duration { font: normal normal normal 18px/30px Outfit;}
    .choose-staking .packages-data .currency { max-width: 145PX;}
    .choose-staking .packages-data .amount { max-width: calc(100% - 155px);}
    .choose-staking .packages-data .amount .fixm, .choose-staking .packages-data .amount-1 .fixm { width: 45px;}
    .choose-staking .packages-data .amount .limit, .choose-staking .packages-data .amount-1 .limit { max-width: calc(100% - 52px);}
    .choose-staking .packages-data .currency-1 { max-width: 98px;}
    .choose-staking .packages-data .amount-1 { max-width: calc(100% - 108px);}
}
@media only screen and (max-width:1399px){
    .choose-staking .packages-data h2 {font-size: 35px;line-height: 45px;}
    .choose-staking .packages-data .span-font {font-size: 20px;}
    .choose-staking .packages-data {padding: 100px 34px 53px;}
    .choose-staking .packages-data .lottie-cover {width: 130px;height: 130px;}
    .choose-staking .packages-data .red-img {left: 18px;width: 20%;}
    .choose-staking .packages-data .red-img .percentage {font-size: 18px;}
    .choose-staking .packages-data .red-img .reward-tag {top: 24px;font-size: 15px;}
    .choose-staking .packages-data button {width: 165px;}
    /*new*/
    .choose-staking .packages-data { min-height: 519px;}
    /**/
    .packages-tab .packages-data .months {margin-left: 10px;width: 165px;}
    .packages-tab .packages-data .min-limit {max-width: calc(100% - 175px);}
    
}
@media only screen and (max-width:1199px){
    .choose-staking .packages-data .lottie-cover {width: 105px;height: 105px;top: -45px;}
    .choose-staking .packages-data .red-img .reward-tag {top: 20px;font-size: 12px;}
    .choose-staking .packages-data {padding: 100px 13px 40px;}
}
@media only screen and (max-width:991px){
    .choose-staking .packages-data {margin: 0 0 100px;}
    .choose-staking {padding: 0px 0 30px;}
    .choose-staking .packages-data .red-img {left: 40px;}
    .choose-staking .all-packages button {min-width: 185px;}
    .choose-staking .packages-area {margin: 0 0 0px;}
}
@media only screen and (max-width:479px){
    .choose-staking .packages-data .red-img {left: 20px;}
    .choose-staking .packages-data .months { width: 155px;}
    .choose-staking .packages-data .min-limit { max-width: calc(100% - 165px);}
    .choose-staking .packages-data .min-limit-1 { max-width: calc(100% - 65px);}
    .choose-staking .packages-data { min-height: 540px;}
    .choose-staking .packages-data .duration { font: normal normal normal 17px/26px Outfit;}
    /**/
    .choose-staking .packages-data .duration {font: normal normal normal 15px/26px Outfit;}
    /**/
    .choose-staking .packages-data .currency-1 { max-width: 71px;}
    .choose-staking .packages-data .amount-1 { max-width: calc(100% - 81px);}
    .choose-staking .packages-data .currency { width: 115px;}
    .choose-staking .packages-data .amount {max-width: calc(100% - 125px);}
    /**/
    .choose-staking .expiry-date span{font-size: 12px;}
}
@media only screen and (max-width:319px){
    .choose-staking .packages-data .lottie-cover {width: 85px;height: 85px;top: -40px;}
    .choose-staking .packages-data .lottie-cover svg { width: 150px !important; height: 150px !important;}
    .choose-staking .packages-data .red-img .percentage {font-size: 15px;top: 0px;}
    .choose-staking .packages-data .red-img .reward-tag {top: 14px;font-size: 12px;}
    .choose-staking .packages-data p {font-size: 16px;}
    .choose-staking .packages-data .duration {font: normal normal normal 16px/26px Outfit;}
    /**/
    .choose-staking .packages-data .duration {font: normal normal normal 14px/26px Outfit;}
    .choose-staking .packages-data .currency {width: 110px;}
    .choose-staking .packages-data .amount {max-width: calc(100% - 120px);}
    .choose-staking .packages-data .amount .fixm, .choose-staking .packages-data .amount-1 .fixm {width: 35px;}
    .choose-staking .packages-data .amount .limit, .choose-staking .packages-data .amount-1 .limit {max-width: calc(100% - 42px);}
}