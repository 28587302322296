.dashboard-header {background: transparent;padding: 21px 39px;display: flex;justify-content: center;/* position: relative; */z-index: 4;position: absolute;width: 100%;top: 0;left: 280px;width: calc(100% - 280px)}
.dashboard-header a{display: inline-flex; justify-content: center; align-items: center;padding: 12px 12px;}
/* .dashboard-header .purple-btn {padding: 10px 37px;} */
.dashboard-header button#dropdown-basic {background-color: transparent;border: none; min-width: 160px;justify-content: center;}
.dashboard-header .connected-user .name {font-size: 20px;line-height: 34px;color: #09091A;display: inline-block;overflow: hidden;max-width: 210px;text-overflow: ellipsis;}
.dashboard-header .connected-user .user-image{width: 34px;height: 34px;border-radius: 50%;overflow: hidden;margin-left: 15px;display: block;}
.dashboard-header .connected-user .user-image img{width: 100%;height: 100%;object-fit: cover;}
.dashboard-header .dropdown-toggle::after {display: inline-block;margin-left: 10px;vertical-align: 0.255em;content: "";border-top: 0.3em solid;border-right: 0.3em solid transparent;border-bottom: 0;border-left: 0.3em solid transparent;color: #000;}
.dashboard-header .dropdown {margin-left: 30px;min-height: 55px;display: flex;align-items: center;border-radius: 4px;border: 1px solid #000;}
.dashboard-header .dropdown:hover {box-shadow: 0px 0px 10px #725fee;}
.dashboard-header .dropdown-menu.show {width: 100%;transform: translate3d(0px, 48px, 0px) !important;padding: 0;border-radius: 0px;}
.dashboard-header .dropdown-item:focus, .dashboard-header .dropdown-item:hover, .dashboard-header .dropdown-item:active{background-color: #401c86;color: #fff;}
.dashboard-header .wallet-datatip{display: none;}
/* responsive */
@media only screen and (max-width:1919px){

}
@media only screen and (max-width:1799px){

}
@media only screen and (max-width:1599px){

}
@media only screen and (max-width:1399px){

}
@media only screen and (max-width:1199px){
    .dashboard-header {left: 240px;width: calc(100% - 240px);}
    .dashboard-header .dropdown {margin-left: 30px;}
    .dashboard-header .purple-btn {font-size: 16px;line-height: 21px;height: 50px;}
    .sidebar .logo {margin-bottom: 40px;}
}
@media only screen and (max-width:991px){
    .dashboard-header {left: 0px;width: calc(100% - 0px);position: fixed;background: #FDE6FF;}
    .dashboard-header .dropdown {margin-left: 30px;margin-right: 30px;}
}
@media only screen and (max-width:767px){
    .dashboard-header { padding: 21px 25px;}
    .dashboard-header .connected-user .name { font-size: 17px; max-width: 176px;}
    .dashboard-header .dropdown { margin-left: 20px;}
}
@media only screen and (max-width:575px){
    .dashboard-header .dropdown {margin-left: 15px;margin-right: 30px;}
    .dashboard-header .connected-user .name {font-size: 16px;max-width: 128px;}
    .dashboard-header .header-button {position: absolute;top: 75px;left: 50%;transform: translateX(-50%);}
    .dashboard-header {height: 100px;}
    .dashboard-header .dropdown { min-height: 50px;}
}
@media only screen and (max-width:479px){
    /* .dashboard-header .dropdown {margin-left: 0px;margin-right: 30px;} */
    /* .dashboard-header .purple-btn { padding: 10px 22px; position: absolute; top: 85px; right: 30px;} */
    .dashboard-header .purple-btn { padding: 10px 25px;}
    /* .dashboard-header button#dropdown-basic {min-width: 130px;} */
    .dashboard-header .dropdown-menu.show {top: 100% !important;left: 50% !important;transform: translateX(-50%) !important;}
    .dashboard-header .connected-user .name {max-width: 120px;}
    /**/
    .dashboard-header .wallet-datatip{display: block;}
}
@media only screen and (max-width:319px){
    .dashboard-header .dropdown {margin-left: 12px;margin-right: 15px;}
    .dashboard-header .connected-user .name {font-size: 16px;line-height: 34px;color: #09091A;}
    .dashboard-header .header-button {right: 20px; min-width: 155px;}
    .dashboard-header button#dropdown-basic {min-width: 145px;}
    .dashboard-header .connected-user .name {max-width: 101px;}
}