.Our-mission {padding: 85px 0;}
.Our-mission .small-head{padding-left: 75px;position: relative;}
.Our-mission .small-head::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);left: 0%;height: 2px;background-color: #725FEE;width: 60px;}
.Our-mission .mission-head{margin: 0 0 42px;}
.Our-mission .mission-para{max-width: 800px;}
.Our-mission .mission-para p{margin: 0 0 42px;}
.Our-mission .mission-para p:last-of-type{margin: 0 0 100px;}
.Our-mission .mission-buttons .transparent-btn {margin-left: 30px;}
.Our-mission .mission-data{max-width: 800px;margin: 0 0 0 auto;}
.Our-mission .mission-buttons .transparent-btn {margin-left: 30px;min-width: 173px;display: flex; justify-content: center; align-items: center;}
.Our-mission .mission-buttons .purple-border-btn {min-width: 165px;}
/* responsive */
@media only screen and (max-width:1399px){
    .Our-mission .mission-buttons .transparent-btn {margin-left: 20px;}
}
@media only screen and (max-width:991px){
    .Our-mission {padding: 30px 0 85px;}
    .Our-mission .mission-img {width: 75%;margin: 0 auto;}
}
@media only screen and (max-width:575px){
    .Our-mission .mission-buttons .transparent-btn {min-width: 153px;}
    .Our-mission .mission-buttons .purple-border-btn {min-width: 145px;}
}
@media only screen and (max-width:479px){
    .Our-mission .mission-img {width: 100%;}
    .Our-mission .mission-buttons .transparent-btn {min-width: 133px;}
    .Our-mission .mission-buttons .purple-border-btn {min-width: 125px;}
}
@media only screen and (max-width:319px){
    .Our-mission .mission-buttons .transparent-btn {min-width: 120px;}
    .Our-mission .mission-buttons .purple-border-btn {min-width: 120px;}
}