.login-section .login-header{padding: 40px 0px;position: absolute; width: 100%;top: 0;left: 0;z-index: 99;}
.login-section .login-header .logo{width: 173px;}
.login-section {background-image: url('../../assets/images/login-bg.jpg');background-repeat: no-repeat;background-size: 100% 100%;height: 100vh;}
.login-section .main-section {height: 100%;}
.login-section .login-lottie-in{max-width: 705px;height: 705px;overflow: hidden;}
.login-section .login-data .login-data-in{    width: 100%;    max-width: 686px;    margin: 0 0 0 auto;}
.login-section .login-data .login-data-in .heading{display: block;font-size: 42px;line-height: 53px;font-weight: bold;margin: 0 0 70px;}
.login-section .login-data .login-data-in .login-form .input-section{margin: 0 0 30px;}
.login-section .login-data .login-data-in .login-form .input-section.last-section{margin: 0 0 10px;}
.login-section .login-data .login-data-in .login-form .input-section .data-label{font-size: 20px;line-height: 25px;font-weight: bold;margin: 0 0 15px;}
.login-section .login-data .login-data-in .login-form .input-section input{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 26px #3524A524;border-radius: 4px;height: 65px;padding: 10px 20px;border: none;}
.login-section .login-data .login-data-in .login-form .input-section .relative-input{position: relative;}
.login-section .login-data .login-data-in .login-form .input-section .relative-input .eye-icon{position: absolute;top: 50%;transform: translateY(-50%);right: 20px;color: #989898;cursor: pointer;}
.login-section .login-data .login-data-in .login-form .input-section .relative-input .eye-icon.active{color: #725FEE;}
.login-section .login-data .login-data-in .login-form .input-section input:focus, .login-section .login-data .login-data-in .login-form .input-section input:focus-visible{outline: none;box-shadow: none;}
.login-section .login-data .login-data-in .login-form .forget-button{margin: 0 0 27px;}
.login-section .login-data .login-data-in .login-form .forget-button .forget-pass{font-size: 18px;line-height: 23px;color: #09091A;}
.login-section .login-data .login-data-in .login-form .login-button{margin: 0 0 30px;}

.login-section .login-data .login-data-in .login-form .login-button a{display: inline-flex; align-items: center; justify-content: center;}

.login-section .login-data .login-data-in .signup-button{font-size: 18px;line-height: 23px;}
.login-section .login-data .login-data-in .signup-button-in .signup-text{font-weight: normal;}
.login-section .login-data .login-data-in .signup-button-in .signup-button{font-weight: bold;color: #725FEE;}
.login-section .login-data .login-data-in .login-form .input-section input[type="password"]{padding: 10px 50px 10px 20px;}
/*overflow scroll*/
.login-section::-webkit-scrollbar {background-color: #fff; width: 10px;}
.login-section::-webkit-scrollbar-button { background-color: #fff; }
.login-section::-webkit-scrollbar-track  { background-color:#fff; }
.login-section::-webkit-scrollbar-track-piece  { background-color: #fff;}
.login-section::-webkit-scrollbar-thumb  {  background:  transparent linear-gradient(277deg, #8758E5 0%, #9053D6 100%) 0% 0% no-repeat padding-box; }
.login-section::-webkit-scrollbar-corner { background-color:#fff;  }
.login-section::-webkit-resizer {background-color:#fff; }
.login-section{scrollbar-color: #9053D6 #fff !important; scrollbar-width: thin;}
/* responsive */
@media only screen and (max-width:1799px){
    .login-section .login-data .login-data-in .heading {font-size: 38px;line-height: 48px;margin: 0 0 55px;}
    .login-section .login-lottie-in {height: 550px;}
}
@media only screen and (max-width:1599px){
    .login-section .login-data .login-data-in .heading {font-size: 32px;line-height: 42px;margin: 0 0 35px;}
    .login-section .login-data .login-data-in .login-form .input-section input {height: 55px;}
}
@media only screen and (max-width:1399px){
    .login-section .login-lottie-in {height: 480px;}
    .login-section .login-data .login-data-in .login-form .input-section input {height: 45px;}
    .login-section .login-header .logo {width: 128px;display: block;}
    .login-section .login-data .login-data-in .login-form .input-section {margin: 0 0 10px;}
    .login-section .login-data .login-data-in .login-form .input-section .data-label {font-size: 16px;line-height: 20px;}
    .login-section .login-data .login-data-in .login-form .forget-button .forget-pass {font-size: 16px;line-height: 20px;}
    .login-section .purple-border-btn {height: 45px;}
}
@media only screen and (max-width:1199px){
    .login-section .login-data .login-data-in .heading {font-size: 28px;line-height: 38px;margin: 0 0 25px;}
}
@media only screen and (max-width:991px){
    .login-section .login-data .login-data-in {padding: 0px 0px;overflow: visible;}
    .login-section .login-data .login-data-in {max-height: none;margin: 0 0 30px;}
    .login-section{overflow-y: auto;}
    .login-section .login-header {padding: 0;position: relative;height: 90px;display: flex;align-items: center;}
    .login-section .main-section {height: calc(100% - 90px);}
}
@media only screen and (max-width:575px){
    .login-section .login-data .login-data-in .heading {font-size: 24px;line-height: 34px;}
}