#footer{background: transparent url('../../assets//images/footer-bg.png') 0% 0% no-repeat padding-box;margin: -100px 0 0;padding: 414px 0 0;}
#footer .footer-logo {width: 226px;height: 55px;margin: 0 0 30px;}
#footer .footer-logo img{width: 100%;height: 100%;}
#footer .footer-para{max-width: 530px;margin: 0 0 27px;}
#footer .footer-para p {font-size: 18px;line-height: 23px;opacity: 0.4;}
#footer .join-section .join-heading{font-size: 20px;line-height: 26px;font-weight: bold;margin: 0 0 30px;display: block;color: #725FEE;}
#footer .social-icons ul{padding: 0;list-style: none;}
#footer .social-icons ul li{margin: 0 6px;}
#footer .social-icons ul li:first-of-type{margin-left: 0;}
#footer .social-icons ul li a{width: 39px;height: 39px;background-color: #fff;border-radius: 50%;display: flex;justify-content: center;align-items: center;transition: all ease-in-out 0.3s;}
#footer .facebook{color: #1877f2;}
#footer .facebook:hover{background-color: #1877f2;color: #fff;}
#footer .linkedin{color: #0a66c2;}
#footer .linkedin:hover{background-color: #0a66c2;color: #fff;}
#footer .twitter{color: #1da1f2;}
#footer .twitter:hover{background-color: #1da1f2;color: #fff;}
#footer .pininterest{color: #bd081c;}
#footer .pininterest:hover{background-color: #bd081c;color: #fff;}
#footer .youtube{color: #ff0000;}
#footer .youtube:hover{background-color: #ff0000;color: #fff;}
#footer .footer-menu h2{font-size: 20px;line-height: 26px;font-weight: bold;color: #725FEE;margin: 0 0 25px;}
#footer .footer-menu ul {padding: 0;list-style: none;}
#footer .footer-menu ul li{margin: 0 0 23px;}
#footer .footer-menu ul li a{font-size: 18px;line-height: 26px;color: #09091A;position: relative;}
#footer .footer-menu ul li a:hover{color: #6F60EE;}
#footer .footer-menu ul li a::after{content: "";position: absolute;bottom: -2px;left: 0;height: 2px;width: 0%;background-color: #6F60EE;transition: all ease-in-out 0.3s;}
#footer .footer-menu ul li a:hover::after{width: 100%;}
#footer .padding-bottom{padding: 0 0 41px;}
#footer .copyright-section {padding: 33px 0px;border-top: 1px solid #D6D4E2;max-width: 95%;margin: 0 auto;}
#footer .copyright-section span{font-size: 18px;line-height: 23px;opacity: 0.4;}
#footer .footer-menu ul li.active a{color: #6F60EE;}
#footer .footer-menu ul li.active a::after{content: "";position: absolute;bottom: -2px;left: 0;height: 2px;width: 100%;background-color: #6F60EE;}
/* responsive */
@media only screen and (max-width:1199px){
    #footer {padding: 313px 0 0;}
}
@media only screen and (max-width:991px){
    #footer {background-size: cover;}
}
@media only screen and (max-width:319px){
    #footer .copyright-section span {font-size: 16px;line-height: 23px;}
    #footer .footer-para p {font-size: 16px;}
}